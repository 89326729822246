import { createSlice } from '@reduxjs/toolkit'

import type { OffendersData } from '../../services/types'
import { usersApi } from '../../services/users'
import type { RootState } from '../../store'

interface IState {
    offendersAllData: OffendersData | null
    error: string | null
    isLoading: boolean
}

const initialState: IState = {
    offendersAllData: null,
    isLoading: false,
    error: null,
}

const offendersSlice = createSlice({
    name: 'offenders',
    initialState,
    reducers: {
        searchOffendersByName: (state, action) => {
            if (state.offendersAllData) {
                state.offendersAllData.offenders =
                    state.offendersAllData.offenders.filter((item: any) =>
                        item.offenderName
                            .toLowerCase()
                            .includes(action.payload.toLowerCase()),
                    )
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(usersApi.endpoints.getUsers.matchPending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addMatcher(
                usersApi.endpoints.getUsers.matchFulfilled,
                (state, { payload }) => {
                    state.isLoading = false
                    if (payload.messages) {
                        state.offendersAllData = null
                    } else {
                        state.offendersAllData = payload
                    }
                },
            )
            .addMatcher(
                usersApi.endpoints.getUsers.matchRejected,
                (state, { error }) => {
                    state.offendersAllData = null
                    state.isLoading = false
                    state.error = error.message || 'An error occurred'
                },
            )
    },
})

export const { searchOffendersByName } = offendersSlice.actions

export default offendersSlice.reducer
