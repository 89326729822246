import React from 'react'

const EmptyAlerts = () => {
    return (
        <div className="w-full h-full col-group items-center justify-center">
            <div className="col-group items-center justify-center text-center">
                <span className="ico mb-10">
                    <svg
                        width="48"
                        height="49"
                        viewBox="0 0 48 49"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g opacity="0.3" clip-path="url(#clip0_960_40545)">
                            <path
                                d="M22.5 10.9996V19.9996C22.5 20.7953 22.1839 21.5583 21.6213 22.1209C21.0587 22.6835 20.2956 22.9996 19.5 22.9996H10.5C9.70435 22.9996 8.94129 22.6835 8.37868 22.1209C7.81607 21.5583 7.5 20.7953 7.5 19.9996V10.9996C7.5 10.204 7.81607 9.44089 8.37868 8.87828C8.94129 8.31567 9.70435 7.9996 10.5 7.9996H19.5C20.2956 7.9996 21.0587 8.31567 21.6213 8.87828C22.1839 9.44089 22.5 10.204 22.5 10.9996ZM37.5 7.9996H28.5C27.7043 7.9996 26.9413 8.31567 26.3787 8.87828C25.8161 9.44089 25.5 10.204 25.5 10.9996V19.9996C25.5 20.7953 25.8161 21.5583 26.3787 22.1209C26.9413 22.6835 27.7043 22.9996 28.5 22.9996H37.5C38.2957 22.9996 39.0587 22.6835 39.6213 22.1209C40.1839 21.5583 40.5 20.7953 40.5 19.9996V10.9996C40.5 10.204 40.1839 9.44089 39.6213 8.87828C39.0587 8.31567 38.2957 7.9996 37.5 7.9996ZM19.5 25.9996H10.5C9.70435 25.9996 8.94129 26.3157 8.37868 26.8783C7.81607 27.4409 7.5 28.204 7.5 28.9996V37.9996C7.5 38.7953 7.81607 39.5583 8.37868 40.1209C8.94129 40.6835 9.70435 40.9996 10.5 40.9996H19.5C20.2956 40.9996 21.0587 40.6835 21.6213 40.1209C22.1839 39.5583 22.5 38.7953 22.5 37.9996V28.9996C22.5 28.204 22.1839 27.4409 21.6213 26.8783C21.0587 26.3157 20.2956 25.9996 19.5 25.9996ZM37.5 25.9996H28.5C27.7043 25.9996 26.9413 26.3157 26.3787 26.8783C25.8161 27.4409 25.5 28.204 25.5 28.9996V37.9996C25.5 38.7953 25.8161 39.5583 26.3787 40.1209C26.9413 40.6835 27.7043 40.9996 28.5 40.9996H37.5C38.2957 40.9996 39.0587 40.6835 39.6213 40.1209C40.1839 39.5583 40.5 38.7953 40.5 37.9996V28.9996C40.5 28.204 40.1839 27.4409 39.6213 26.8783C39.0587 26.3157 38.2957 25.9996 37.5 25.9996Z"
                                fill="#8B8B8B"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_960_40545">
                                <rect
                                    width="48"
                                    height="48"
                                    fill="white"
                                    transform="translate(0 0.5)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </span>
                <h1 className="color-text fw-500 mb-10">No Alerts Yet</h1>
                <p className="color-text fw-500 ">
                    Please set up an alert to monitor ofender.
                </p>
            </div>
        </div>
    )
}

export default EmptyAlerts
