export enum SafeZoneTypes {
    HOME = 'HouseLine',
    SCHOOL = 'GraduationCap',
    WORK = 'Briefcase',
    BED = 'Bed',
    BANK = 'Bank',
    LIBRARY = 'BookOpenUser',
    MAP_PIN = 'MapPin',
    SHOP = 'Basket',
    COFFEE = 'Coffee',
    CAFE = 'Hamburger',
    HOSPITAL = 'Hospital',
    TRANSPORT = 'Train',
}
