/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx'
import type { ReactNode } from 'react'
import React, { useState } from 'react'

import AccordionIcon from '../../../assets/img/ico-carret-drp.svg?react'
interface AccordionProps {
    title: string
    children: ReactNode
    icon: ReactNode
    onReset?: () => void
}

const Accordion: React.FC<AccordionProps> = ({
    title,
    children,
    icon,
    onReset,
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleAccordion = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div
            className={clsx('accordion accordeon-filter', {
                'active-accordion': isOpen,
            })}
        >
            <div
                className="accordeon-btn accordeon-filter--btn"
                onClick={toggleAccordion}
            >
                <div className="accordeon-filter--title">
                    <span className="ico color-secondary">{icon}</span>
                    <p className="name">{title}</p>
                </div>
                <div className="accordeon-filter--actions">
                    <button
                        className="btn color-secondary"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {onReset ? (
                            <span
                                className="font-500 text--lg"
                                onClick={onReset}
                            >
                                Reset
                            </span>
                        ) : null}
                    </button>
                    <button className="btn btn-toggle--accordeon">
                        <span
                            className={clsx('ico', {
                                'rotate-180': isOpen,
                            })}
                        >
                            <AccordionIcon />
                        </span>
                    </button>
                </div>
            </div>
            {isOpen && <div className="accordion-body">{children}</div>}
        </div>
    )
}

export default Accordion
