import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { createBrowserHistory } from 'history'
import { combineReducers } from 'redux'
import { createReduxHistoryContext } from 'redux-first-history'

import alertsReducer from './features/alerts/alertsSlice'
import authReducer from './features/auth/authSlice'
import offendersSlice from './features/offenders/offendersSlice'
import { authApi } from './services/auth'
import { baseApi } from './services/base'
import { familyApi } from './services/family'
import { notificationsApi } from './services/notifications'
import { usersApi } from './services/users'

// Setup redux-first-history
const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({ history: createBrowserHistory() })
export const store = configureStore({
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([
            authApi.middleware,
            usersApi.middleware,
            notificationsApi.middleware,
            familyApi.middleware,
            baseApi.middleware,
            routerMiddleware,
        ]),
    reducer: combineReducers({
        router: routerReducer,
        auth: authReducer,
        offenders: offendersSlice,
        allerts: alertsReducer,
        [baseApi.reducerPath]: baseApi.reducer,
        // [notificationsApi.reducerPath]: notificationsApi.reducer,
        // [familyApi.reducerPath]: familyApi.reducer,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)
export const history = createReduxHistory(store)
