import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import EditIcon from '../../../assets/img/edit-user-icon.svg?react'
import PluseIcon from '../../../assets/img/pluse-icon.svg?react'
import TrashIcon from '../../../assets/img/trashIcon.svg?react'
import { getIsPremium, getUser } from '../../../features/auth/authSlice'
import { useAppSelector } from '../../../hooks/redux'
import { useDeleteSaveZoneMutation } from '../../../services/family'
import type { ISaveZone } from '../../../types'

import type { SaveZoneType } from './AddSaveZone'
import AddSaveZone, { saveZonesAll } from './AddSaveZone'
import type { IFamilySidebar } from './FamilySidebar'

const SaveZonesTab = ({
    saveZones,
}: Omit<IFamilySidebar, 'users' | 'selectedUser' | 'setSelectedUser'>) => {
    const { t } = useTranslation('members')
    const [editZone, setEditZone] = useState<ISaveZone | null>(null)
    const user = useAppSelector(getUser)
    const [addSaveZoneModal, setAddSaveZoneModal] = useState<boolean>(false)
    const [deleteZone] = useDeleteSaveZoneMutation()
    const saveZoneModalHandler = () => {
        setAddSaveZoneModal((prev) => !prev)
    }
    const getZoneIcon = (type: string): SaveZoneType => {
        return saveZonesAll.filter((zone) => zone.id === type)[0]
    }

    const removezoneHandler = async (id: number) => {
        try {
            const res = await deleteZone(id).unwrap()
            if (res.success) {
                toast.success(res?.message || 'Яone deleted')
            } else {
                toast.error(res?.message || 'Something went wrong')
            }
        } catch (error) {
            console.error('rejected', error)
        }
    }

    return (
        <>
            <div className="tab mb-20 active-tab">
                <ul className="family-list mb-32">
                    {saveZones.map((zone, index) => (
                        <li className="family-list__item" key={index}>
                            <div className="btn family-link w-full family-link-map-veiw">
                                <div className="ico ico--sm family-link__icon rounded-full">
                                    {getZoneIcon(zone.type).icon}
                                </div>
                                <div className="family-link__text flex-auto">
                                    <div className="family-link__title">
                                        {zone.name}
                                    </div>
                                    <div className={'family-link__text'}>
                                        {zone.address}
                                    </div>
                                </div>
                                <div className="family-link__actions">
                                    <button
                                        onClick={() => {
                                            setEditZone(zone)
                                            saveZoneModalHandler()
                                        }}
                                        className="btn"
                                    >
                                        <span className="ico">
                                            <EditIcon />
                                        </span>
                                    </button>
                                    <button
                                        onClick={async () =>
                                            removezoneHandler(zone.id)
                                        }
                                        className="btn color-danger"
                                    >
                                        <span className="ico">
                                            <TrashIcon />
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                {!user?.familyMember && (
                    <button
                        type="button"
                        onClick={saveZoneModalHandler}
                        className="btn btn--primary btn--lg rounded-full w-full"
                    >
                        <span className="ico">
                            <PluseIcon />
                        </span>
                        <span>{t('sidebar.add_zones')}</span>
                    </button>
                )}
            </div>
            {addSaveZoneModal && (
                <AddSaveZone
                    isOpen={addSaveZoneModal}
                    onClose={saveZoneModalHandler}
                    editZone={editZone}
                    setEditZone={setEditZone}
                />
            )}
        </>
    )
}

export default SaveZonesTab
