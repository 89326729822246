import { data } from 'autoprefixer'
import React, { useEffect, useState } from 'react'
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import CnFlagSVG from '../../assets/img/flags/cn.svg'
import DeFlagSVG from '../../assets/img/flags/de.svg'
import EsFlagSVG from '../../assets/img/flags/es.svg'
import FrFlagSVG from '../../assets/img/flags/fr.svg'
import InFlagSVG from '../../assets/img/flags/in.svg'
import ItFlagSVG from '../../assets/img/flags/it.svg'
import JpFlagSVG from '../../assets/img/flags/jp.svg'
import KrFlagSVG from '../../assets/img/flags/kr.svg'
import NlFlagSVG from '../../assets/img/flags/nl.svg'
import PtFlagSVG from '../../assets/img/flags/pt.svg'
import SaFlagSVG from '../../assets/img/flags/sa.svg'
import TrFlagSVG from '../../assets/img/flags/tr.svg'
import UaFlagSVG from '../../assets/img/flags/ua.svg'
import EnFlagSVG from '../../assets/img/flags/us.svg'
import { useGetMeQuery, useUpdateMeMutation } from '../../services/auth'

export const LanguagePage: React.FC = () => {
    const { data: dataUser, refetch } = useGetMeQuery()
    const [triggerUpdateMe] = useUpdateMeMutation()
    const [isReqLoading, setIsReqLoading] = useState(false)

    const [activeLang, setActiveLang] = useState<string | null>(null)
    const {
        t,
        i18n: { changeLanguage, language },
    } = useTranslation('settings')
    console.log(dataUser)

    useEffect(() => {
        if (dataUser?.data) {
            setActiveLang(dataUser?.data?.language)
        }
    }, [dataUser?.data])

    const handleChangeLanguage = (e: React.ChangeEvent<HTMLInputElement>) => {
        setActiveLang(e.currentTarget.id)
        // changeLanguage(e.currentTarget.id)
    }

    const onSubmit = async () => {
        if (!activeLang) return
        const formData = new FormData()

        formData.append('language', activeLang)

        try {
            setIsReqLoading(true)
            const res = await triggerUpdateMe(formData).unwrap()

            if (res.success) {
                refetch()

                toast.success('Language updated successfully', {
                    autoClose: 3000,
                })
                changeLanguage(activeLang)
            } else {
                toast.error('Language not updated ', {
                    autoClose: 3000,
                })
            }
            console.log(res, 'res update user')
        } catch (e) {
        } finally {
            setIsReqLoading(false)
        }
    }

    const radioData = [
        {
            value: 'en',
            label: 'English',
            image: EnFlagSVG,
        },
        {
            value: 'ua',
            label: 'Українська (Ukrainian)',
            image: UaFlagSVG,
        },
        {
            value: 'es',
            label: 'Español (Spanish)',
            image: EsFlagSVG,
        },
        {
            value: 'fr',
            label: 'Français (French)',
            image: FrFlagSVG,
        },
        {
            value: 'de',
            label: 'Deutsch (German)',
            image: DeFlagSVG,
        },
        {
            value: 'cn',
            label: '中文 (Chinese)',
            image: CnFlagSVG,
        },
        {
            value: 'jp',
            label: '日本語 (Japanese)',
            image: JpFlagSVG,
        },
        {
            value: 'kr',
            label: '한국어 (Korean)',
            image: KrFlagSVG,
        },
        {
            value: 'pt',
            label: 'Português (Portuguese)',
            image: PtFlagSVG,
        },
        {
            value: 'it',
            label: 'Italian (Italian)',
            image: ItFlagSVG,
        },
        {
            value: 'sa',
            label: 'العربية (Arabic)',
            image: SaFlagSVG,
        },
        {
            value: 'in',
            label: 'हिंदी (Hindi)',
            image: InFlagSVG,
        },
        {
            value: 'tr',
            label: 'Türkçe (Turkish)',
            image: TrFlagSVG,
        },
        {
            value: 'nl',
            label: 'Nederlands (Dutch)',
            image: NlFlagSVG,
        },
    ]

    return (
        <div className="tab active-tab" data-id="tab_language">
            <div className="settings__header">
                <h2 className="fw-500">{t('language.title')}</h2>
                <button
                    disabled={isReqLoading}
                    onClick={(e) => {
                        e.preventDefault()
                        onSubmit()
                    }}
                    className="btn btn--primary btn--sm radius-full"
                >
                    {t('language.save')}
                </button>
            </div>
            <div className="settings__body-content">
                <ul className="lang-list">
                    {radioData.map((el) => (
                        <li key={el.value} className="lang-list--item">
                            <label className="check-form check--xs w-full btn btn--lg">
                                <input
                                    type="radio"
                                    name="select-lang"
                                    id={el.value}
                                    hidden
                                    checked={activeLang === el.value}
                                    onChange={handleChangeLanguage}
                                />
                                <div className="check-row">
                                    <span className="check-control check-control--custom"></span>
                                    <div className="check-content my-auto --lang-select">
                                        <span className="ico">
                                            <img
                                                src={el.image}
                                                alt={el.label}
                                            />
                                        </span>
                                        <p className="check-text text--md">
                                            {el.label}
                                        </p>
                                    </div>
                                </div>
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
