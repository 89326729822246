import React from 'react'

interface TableHeaderProps {
    columns: string[]
}

const TableHeader: React.FC<TableHeaderProps> = ({ columns }) => (
    <thead>
        <tr>
            {columns.map((column, index) => (
                <th key={index} style={{ width: index === 1 ? 270 : 'auto' }}>
                    {column}
                </th>
            ))}
        </tr>
    </thead>
)

interface TableProps {
    columns: string[]
    children: React.ReactNode
}

const Table: React.FC<TableProps> = ({ columns, children }) => (
    <div className="table-wrapper scroll-styler h-full">
        <table className="table" cellPadding="0" cellSpacing="0">
            <TableHeader columns={columns} />
            <tbody>{children}</tbody>
        </table>
    </div>
)

export default Table
