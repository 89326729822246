import type { ISubscription, SubscriptionPeriod } from '../types'

import { baseApi } from './base'
import type { IResponseType } from './types'

const base = 'api/subscriptions'

export const subscriptionsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getMySubscription: builder.query<
            IResponseType<{ [key: string]: ISubscription }>,
            void
        >({
            query: () => `${base}`,
            providesTags: ['subscriptions'],
        }),
        createSubscription: builder.mutation<
            IResponseType<{ clientSecret: string }>,
            { type: SubscriptionPeriod }
        >({
            query: (body) => ({
                url: `${base}`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['subscriptions', 'me'],
        }),
        // startTrialSubscription: builder.mutation<
        //     IResponseType<{ clientSecret: string }>,
        //     void
        // >({
        //     query: () => `${base}/trial`,
        //     invalidatesTags: ['subscriptions', 'me'],
        // }),
        cancelSubscription: builder.mutation<
            IResponseType<{ clientSecret: string }>,
            void
        >({
            query: () => ({
                method: 'PUT',
                url: `${base}`,
            }),
            invalidatesTags: ['subscriptions', 'me'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useCreateSubscriptionMutation,
    useCancelSubscriptionMutation,
    useGetMySubscriptionQuery,
    useLazyGetMySubscriptionQuery,
} = subscriptionsApi
