/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import ListViewIcon from '../../../assets/img/ico-list.svg?react'
import MapViewIcon from '../../../assets/img/ico-map-view.svg?react'
import Pagination from '../../../compoments/Pagination'
import SelectBox from '../../../compoments/SelectBox'
import { useAppSelector } from '../../../hooks/redux'
import { useTabs } from '../../../hooks/useTabs'
import { useGetMeQuery } from '../../../services/auth'
import type { IOffender } from '../../../types'
import { SearchFilters } from '../index'

import CardPerson from './CardPerson'
import Map from './Map'
import OffenderPopup from './OffenderPopup'

const tabs = [
    {
        id: 'list_view',
        label: 'List View',
        icon: <ListViewIcon />,
    },
    {
        id: 'map_view',
        label: 'Map View',
        icon: <MapViewIcon />,
    },
] as const

const showUserPerPage = window.innerWidth < 992 ? 10 : 15

export const Offenders = () => {
    const { t } = useTranslation('home')
    const [searchParams] = useSearchParams()
    const [offenderPopup, setOffenderPopup] = useState<IOffender | null>(null)
    const sortOptions = [
        {
            label: t('sort.default'),
            value: 'default',
        },
        {
            label: t('sort.by_range'),
            value: 'range',
        },
        {
            label: t('sort.by_name'),
            value: 'name',
        },
        {
            label: t('sort.by_state'),
            value: 'state',
        },
    ]
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedSortOption, setSelectedSortOption] = useState<string | null>(
        null,
    )

    const { activeTab, setActiveTab } = useTabs(tabs)

    const { isLoading, offendersAllData } = useAppSelector(
        (state) => state.offenders,
    )

    const { data: userData } = useGetMeQuery()

    const selectedOffenders = userData?.data?.offenders || []

    const offenders: IOffender[] | null = offendersAllData?.offenders || null

    // const [zipCodes, setZipCodes] = useState<string[]>([])
    // const [states, setStates] = useState<string[]>([])
    // const [range, setRange] = useState<string[]>([])
    const zipCodes = searchParams.get(SearchFilters.ZIPCODES)
    const states = searchParams.get(SearchFilters.STATES)
    const range = searchParams.get(SearchFilters.RANGE)
    // useEffect(() => {
    //   setZipCodes(
    //     searchParams
    //       .get(SearchFilters.ZIPCODES)
    //       ?.split(',')
    //       .filter((el) => el) || [],
    //   )
    //   setStates(
    //     searchParams
    //       .get(SearchFilters.STATES)
    //       ?.split(',')
    //       .filter((el) => el) || [],
    //   )
    //   setRange(
    //     searchParams
    //       .get(SearchFilters.RANGE)
    //       ?.split(',')
    //       .filter((el) => el) || [],
    //   )
    // }, [searchParams])

    const filteredOfferends = useMemo(() => {
        if (!offenders || !offenders.length) return []

        let newOfferends = [...offenders]

        if (zipCodes && zipCodes.length > 0) {
            newOfferends = newOfferends.filter(
                (el) =>
                    el.address?.length &&
                    el.address.some((address) =>
                        zipCodes.includes(address.zipcode),
                    ),
            )
        }

        if (states && states.length > 0) {
            newOfferends = newOfferends.filter(
                (el) =>
                    el.address?.length &&
                    el.address.some((address) =>
                        states.includes(address.state),
                    ),
            )
        }

        if (range && range.length > 0) {
            newOfferends = newOfferends.filter(
                (el) => el.age >= +range[0] && el.age <= +range[1],
            )
        }

        setCurrentPage(1)

        return newOfferends
    }, [zipCodes, states, offenders])

    const pageCount = Math.ceil(filteredOfferends.length / showUserPerPage)

    let newFilteredOfferends = [...filteredOfferends]

    if (selectedSortOption) {
        switch (selectedSortOption) {
            case 'range':
                newFilteredOfferends.sort((a, b) => a.age - b.age)
                break
            case 'name':
                newFilteredOfferends.sort((a, b) =>
                    a.offenderName.localeCompare(b.offenderName),
                )
                break
            case 'state':
                newFilteredOfferends.sort((a, b) =>
                    a.address && b.address?.length
                        ? a.address[0].state.localeCompare(b.address[0].state)
                        : -1,
                )
                break
            case 'default':
                newFilteredOfferends = [...filteredOfferends]
                break
        }
    }

    const actualOffenders = newFilteredOfferends.slice(
        currentPage === 1 ? 0 : (currentPage - 1) * showUserPerPage,
        currentPage * showUserPerPage,
    )

    return (
        <div className="catalog-page-content">
            <div className="card card-border h-full scroll-card">
                <div className="card-header header-similar card-header-catalog">
                    <div className="card-header--item card-header--item--overflow">
                        <h2 className="heading font-500 card-header--item--title">
                            {t('title')}{' '}
                            <span className="dark-grey-light">
                                ({filteredOfferends.length})
                            </span>
                        </h2>
                        <div className="tabs-nav-2">
                            {tabs.map((tab) => (
                                <button
                                    key={tab.id}
                                    className={clsx(
                                        'tab-link-3 btn btn--sm rounded-full',
                                        {
                                            'active-tab': activeTab === tab.id,
                                        },
                                    )}
                                    onClick={() => setActiveTab(tab.id)}
                                >
                                    <span className="ico">{tab.icon}</span>
                                    <span className="tab-link-3--catalog-title">
                                        {t(tab.id)}
                                    </span>
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="card-header--item">
                        <SelectBox
                            wrapperClassName={
                                'select--outline input--sm select-w-catalog'
                            }
                            options={sortOptions}
                            selectValue={selectedSortOption}
                            onChange={(el) =>
                                setSelectedSortOption(el?.value as string)
                            }
                            placeholder={t('sort.select')}
                        />
                    </div>
                </div>
                <div className="card-body p-0 scroll-card h-full">
                    {
                        !isLoading &&
                        actualOffenders &&
                        actualOffenders.length ? (
                            <>
                                {activeTab === 'list_view' && (
                                    <div
                                        className={`tab h-full active-tab`}
                                        data-id="list-view"
                                    >
                                        <div className="card h-full scroll-card">
                                            <div
                                                className="card-header"
                                                style={{ display: 'none' }}
                                            ></div>
                                            <div className="card-body">
                                                <div className="custom-row custom-row-5 gutters-1">
                                                    {actualOffenders.map(
                                                        (el) => (
                                                            <div
                                                                onClick={() =>
                                                                    setOffenderPopup(
                                                                        el,
                                                                    )
                                                                }
                                                                className="custom-col"
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                                key={el.id}
                                                            >
                                                                <CardPerson
                                                                    data={el}
                                                                    selectedOffenders={
                                                                        selectedOffenders
                                                                    }
                                                                />
                                                            </div>
                                                        ),
                                                    )}
                                                </div>
                                            </div>
                                            {pageCount ? (
                                                <div className="card-footer pagination-wrapper card-footer-sticky">
                                                    <Pagination
                                                        pageCount={pageCount}
                                                        currentPage={
                                                            currentPage
                                                        }
                                                        onPageChange={(
                                                            page: number,
                                                        ) => {
                                                            setCurrentPage(page)
                                                        }}
                                                    />
                                                </div>
                                            ) : null}
                                        </div>
                                        {offenderPopup && (
                                            <OffenderPopup
                                                data={offenderPopup}
                                                isOpen={!!offenderPopup}
                                                onClose={() =>
                                                    setOffenderPopup(null)
                                                }
                                            />
                                        )}
                                    </div>
                                )}
                                {activeTab === 'map_view' && (
                                    <div
                                        className={`tab h-full active-tab`}
                                        data-id="map-view"
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        >
                                            <Map
                                                offenders={filteredOfferends}
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : null
                        // <h2 className="heading font-500 mx-auto mt-5">No items found</h2>
                    }
                    {isLoading && (
                        <div className="offender-loading-container">
                            Loading...
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
