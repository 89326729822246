/* eslint-disable jsx-a11y/label-has-associated-control */

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { IFamilyMember, ISaveZone } from '../../../types'

import Map from './Map'

interface IFamilyOffenders {
    saveZones: ISaveZone[]
    users: IFamilyMember[]
    selectedUser: IFamilyMember | null
    setSelectedUser: (value: IFamilyMember | null) => void
}

interface CheckboxOption {
    id: 'offenders' | 'members'
    label: string
}

export type VisibleFamilyMapItems = {
    [key in CheckboxOption['id']]: boolean
}

const FamilyOffenders = ({
    saveZones,
    users,
    selectedUser,
    setSelectedUser,
}: IFamilyOffenders) => {
    const { t } = useTranslation('members')

    const options: CheckboxOption[] = [
        { id: 'offenders', label: t('filters.sex_offenders') },
        { id: 'members', label: t('filters.family_members') },
    ]

    const [visibleItems, setVisibleItems] = useState<VisibleFamilyMapItems>({
        offenders: true,
        members: true,
    })
    const handleCheckboxChange = (id: CheckboxOption['id']) => {
        setVisibleItems((prev) => ({
            ...prev,
            [id]: !prev[id],
        }))
    }
    return (
        <div className="catalog-page-content">
            <div className="card card-border h-full scroll-card">
                <div className="card-header header-similar card-header-catalog">
                    <div className="card-header--item card-header--item--overflow">
                        {selectedUser && (
                            <button
                                type="button"
                                onClick={() => setSelectedUser(null)}
                                className="btn"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                >
                                    <path
                                        d="M21 12.4277H4.5"
                                        stroke="black"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M11.25 5.67773L4.5 12.4277L11.25 19.1777"
                                        stroke="black"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                        )}
                        <h2 className="heading font-500 card-header--item--title">
                            {selectedUser ? selectedUser.fullName : t('title')}
                        </h2>
                    </div>
                    {!selectedUser && (
                        <div className="card-header--item">
                            {options.map(({ id, label }) => (
                                <label
                                    key={id}
                                    className="check-form check--xs check-rounded-full"
                                >
                                    <input
                                        type="checkbox"
                                        id={id}
                                        hidden
                                        checked={visibleItems[id]}
                                        onChange={() =>
                                            handleCheckboxChange(id)
                                        }
                                    />
                                    <div className="check-row">
                                        <span className="check-control check-control--custom"></span>
                                        <div className="check-content my-auto">
                                            <p className="check-text text--md">
                                                {label}
                                            </p>
                                        </div>
                                    </div>
                                </label>
                            ))}
                        </div>
                    )}
                </div>
                <div className="card-body p-0 scroll-card h-full">
                    <div className={`tab h-full active-tab`} data-id="map-view">
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <Map
                                users={users}
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                saveZones={saveZones}
                                visibleItems={visibleItems}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FamilyOffenders
