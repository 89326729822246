import clsx from 'clsx'
import type { MouseEventHandler } from 'react'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'

import LogoIcon from '../assets/img/logo.svg?react'

const sizes = {
    lg: 'h-16 w-16',
    md: 'h-8 w-8',
    sm: 'h-4 w-4',
    xl: 'h-24 w-24',
}

const variants = {
    light: 'text-white',
    primary: 'text-blue-200',
}

export type FormHeaderProps = {
    className?: string
    size?: keyof typeof sizes
    variant?: keyof typeof variants
    hasLogo?: boolean
    hasArrow?: boolean
    isLoading?: boolean
    handleBack?: MouseEventHandler<HTMLButtonElement>
    heading: string
    description: string | React.ReactNode
}

const FormHeader: React.FC<FormHeaderProps> = ({
    className,
    size,
    variant,
    hasLogo = false,
    hasArrow = false,
    isLoading = false,
    handleBack,
    heading,
    description,
}) => {
    return (
        <div className="form-header flex flex-col">
            {hasLogo && (
                <div className="mb-40">
                    <Link to="/">
                        <LogoIcon className="form-header_logo" />
                    </Link>
                </div>
            )}
            {hasArrow && (
                <button
                    disabled={isLoading}
                    onClick={handleBack}
                    className="btn btn--outline-default rounded-full btn--square btn--xs mb-44"
                >
                    <span className="ico">
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g>
                                <path
                                    d="M12.5 16.25L6.25 10L12.5 3.75"
                                    stroke="currentColor"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                ></path>
                            </g>
                            <defs>
                                <clipPath>
                                    <rect
                                        width="20"
                                        height="20"
                                        fill="white"
                                    ></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                </button>
            )}
            <div className="heading-block">
                <h1 className="heading fw-500 mb-10">{heading}</h1>
                <p className="color-text text--lg mb-40">{description}</p>
            </div>
        </div>
    )
}

export default FormHeader
