import type { ReactNode } from 'react'
import React, { createContext, useState } from 'react'
import { Outlet } from 'react-router-dom'

import ModalContainer from './ModalContainer'

export type ModalType = 'upgrade'

interface ModalContextValue {
    showModal: (type: ModalType, props?: Record<string, unknown>) => void
    hideModal: () => void
}

export const ModalContext = createContext<ModalContextValue | null>(null)

export const ModalProvider = () => {
    const [modal, setModal] = useState<{
        type: ModalType
        props?: Record<string, unknown>
    } | null>(null)

    const showModal = (type: ModalType, props?: Record<string, unknown>) => {
        setModal({ type, props })
    }

    const hideModal = () => {
        setModal(null)
    }

    return (
        <ModalContext.Provider value={{ showModal, hideModal }}>
            <Outlet />
            {modal && <ModalContainer modal={modal} hideModal={hideModal} />}
        </ModalContext.Provider>
    )
}
