import {
    Elements,
    PaymentElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js'
import type { StripeElementsOptions, StripeError } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import Button from '../../compoments/Button'
import FormHeader from '../../compoments/FormHeader'
import { useLazyGetMeQuery } from '../../services/auth'
import { getMediaUrl } from '../../utils/helpers'

const CheckoutForm = ({ clientSecret }: { clientSecret: string }) => {
    const stripe = useStripe()
    const elements = useElements()
    const navigate = useNavigate()
    const { state } = useLocation()
    const [getMe] = useLazyGetMeQuery()

    const [errorMessage, setErrorMessage] = useState<
        StripeError['message'] | null
    >()
    const [isProcessing, setIsProcessing] = useState(false)

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()

        if (!stripe || !elements) {
            toast.error('Stripe has not loaded yet.')
            setErrorMessage('Stripe has not loaded yet.')
            return
        }

        setIsProcessing(true)
        setErrorMessage(null)

        const { error: elementError } = await elements.submit()
        if (elementError) {
            toast.error(elementError.message || 'Payment submission failed.')
            setErrorMessage(
                elementError.message || 'Payment submission failed.',
            )
            setIsProcessing(false)
            return
        }
        const { error } = await stripe.confirmPayment({
            clientSecret,
            elements,
            confirmParams: {
                return_url: getMediaUrl('', ''),
            },
            redirect: 'if_required',
        })

        if (error) {
            toast.error(error.message || 'Payment failed.')
            setErrorMessage(error.message || 'Payment failed.')
            setIsProcessing(false)
        } else {
            toast.success('Payment succeeded!')
            setIsProcessing(false)
            await getMe()
            navigate(state?.redirectTo || '/')
        }
        setIsProcessing(false)
    }
    return (
        <form className="flex flex-col flex-auto" onSubmit={handleSubmit}>
            <FormHeader
                hasLogo
                heading={'Get Guarderly Premium'}
                description={
                    'Gain full access to vital information about potential threats in your area.'
                }
            />
            <PaymentElement />
            {/* <CardElement /> */}

            <div className="form-footer">
                <Button
                    isLoading={isProcessing}
                    className="mt-4"
                    type="submit"
                    disabled={!stripe || !elements}
                >
                    Pay
                </Button>
                {errorMessage && <div>{errorMessage}</div>}
            </div>
        </form>
    )
}

const stripePromise = loadStripe(
    'pk_test_51PhmnU2NXaTKKpTl0SqJWAi0pzKUupBA7aAC9AC7XYU08RWyauzA3MsDEDUlwcaTF9iqGNOE1gX3ksA15VyiU23j00lBpEwYIC',
)

const CheckoutPage = () => {
    const { clientSecret } = useParams()
    const navigate = useNavigate()
    const { state } = useLocation()

    const options: StripeElementsOptions = {
        clientSecret,
    }

    if (!clientSecret) {
        toast.error('Client secret is not found.')
        navigate(state?.redirectTo || '/')
        return null
    }
    return (
        <Elements stripe={stripePromise} options={options}>
            <CheckoutForm clientSecret={clientSecret} />
        </Elements>
    )
}

export default CheckoutPage
