import clsx from 'clsx'
import mapboxgl from 'mapbox-gl'
import type { Marker } from 'mapbox-gl'
import React, { useEffect, useRef, useState } from 'react'
import type { ReactNode } from 'react'
import { toast } from 'react-toastify'

import MinusIcon from '../../../assets/img/ico-calc-minus.svg?react'
import PlusIcon from '../../../assets/img/ico-calc-plus.svg?react'
import SaveZone1 from '../../../assets/img/icon-safe-zone-1.svg?react'
import SaveZone10 from '../../../assets/img/icon-safe-zone-10.svg?react'
import SaveZone11 from '../../../assets/img/icon-safe-zone-11.svg?react'
import SaveZone12 from '../../../assets/img/icon-safe-zone-12.svg?react'
import SaveZone2 from '../../../assets/img/icon-safe-zone-2.svg?react'
import SaveZone3 from '../../../assets/img/icon-safe-zone-3.svg?react'
import SaveZone4 from '../../../assets/img/icon-safe-zone-4.svg?react'
import SaveZone5 from '../../../assets/img/icon-safe-zone-5.svg?react'
import SaveZone6 from '../../../assets/img/icon-safe-zone-6.svg?react'
import SaveZone7 from '../../../assets/img/icon-safe-zone-7.svg?react'
import SaveZone8 from '../../../assets/img/icon-safe-zone-8.svg?react'
import SaveZone9 from '../../../assets/img/icon-safe-zone-9.svg?react'
import PanIcon from '../../../assets/img/pen-icon.svg?react'
import Dropdown from '../../../compoments/Dropdown'
import Input from '../../../compoments/Input'
import Modal from '../../../compoments/Modal'
import { SafeZoneTypes } from '../../../constants/saveZones'
import {
    useCreateSaveZoneMutation,
    useUpdateSaveZoneMutation,
} from '../../../services/family'
import { ISaveZone } from '../../../types'


const mapBoxToken = import.meta.env.VITE_MAP_BOX_KEY
mapboxgl.accessToken = mapBoxToken

export interface SaveZoneType {
    id: SafeZoneTypes
    icon: ReactNode
}

export const saveZonesAll: SaveZoneType[] = [
    {
        id: SafeZoneTypes.HOME,
        icon: <SaveZone1 />,
    },
    {
        id: SafeZoneTypes.SCHOOL,
        icon: <SaveZone2 />,
    },
    {
        id: SafeZoneTypes.WORK,
        icon: <SaveZone3 />,
    },
    {
        id: SafeZoneTypes.BED,
        icon: <SaveZone4 />,
    },
    {
        id: SafeZoneTypes.BANK,
        icon: <SaveZone5 />,
    },
    {
        id: SafeZoneTypes.LIBRARY,
        icon: <SaveZone6 />,
    },
    {
        id: SafeZoneTypes.MAP_PIN,
        icon: <SaveZone7 />,
    },
    {
        id: SafeZoneTypes.SHOP,
        icon: <SaveZone8 />,
    },
    {
        id: SafeZoneTypes.COFFEE,
        icon: <SaveZone9 />,
    },
    {
        id: SafeZoneTypes.CAFE,
        icon: <SaveZone10 />,
    },
    {
        id: SafeZoneTypes.HOSPITAL,
        icon: <SaveZone11 />,
    },
    {
        id: SafeZoneTypes.TRANSPORT,
        icon: <SaveZone12 />,
    },
]

interface AddSaveZoneProps {
    isOpen: boolean
    onClose: () => void
    editZone: ISaveZone | null
    setEditZone: React.Dispatch<React.SetStateAction<ISaveZone | null>>
}

const AddSaveZone = ({
    isOpen,
    onClose,
    setEditZone,
    editZone,
}: AddSaveZoneProps) => {
    const mapContainerRef = useRef<HTMLDivElement>(null)
    const [createSaveZone] = useCreateSaveZoneMutation()
    const [updateSaveZone] = useUpdateSaveZoneMutation()
    const [range, setRange] = useState(0)
    const [selectedZone, setSelectedZone] = useState(saveZonesAll[0])
    const [markerPosition, setMarkerPosition] = useState<[number, number]>([
        -78.16, 39.197372,
    ])
    const [mapCenter, setMapCenter] = useState<[number, number]>([
        -78.16, 39.197372,
    ])
    const [address, setAddress] = useState('')
    const [name, setName] = useState('Home')

    const markerRef = useRef<Marker | null>(null)
    const initMap = (mapContainer: HTMLDivElement | null) => {
        if (!mapContainer) return
        const map = new mapboxgl.Map({
            container: mapContainer,
            style: 'mapbox://styles/maximokolzin/clxd74ktr02dc01pnb0ra8sd0',
            center: mapCenter,
            zoom: 13.5,
        })

        const createHomeMarkerElement = (): HTMLDivElement => {
            const el = document.createElement('div')
            el.innerHTML = `<div class="home-marker marker-modal">
							<div class="home-marker__inner">
								<div class="ico">
					
								</div>
							</div>
						</div>`
            return el
        }

        markerRef.current = new mapboxgl.Marker({
            element: createHomeMarkerElement(),
            draggable: true,
        })
            .setLngLat(markerPosition)
            .addTo(map)

        markerRef.current.on('dragend', () => {
            const lngLat = markerRef.current?.getLngLat()
            if (lngLat) {
                setMarkerPosition([lngLat.lng, lngLat.lat])
            }
        })
        map.addControl(new mapboxgl.NavigationControl(), 'bottom-right')
        map.on('load', () => {
            map.resize()
        })
        return map
    }
    useEffect(() => {
        const map = initMap(mapContainerRef.current)
        return () => map && map.remove()
    }, [mapCenter])
    useEffect(() => {
        reverseGeocode()
    }, [markerPosition])
    useEffect(() => {
        if (editZone) {
            setRange(editZone.radius)
            setSelectedZone(
                saveZonesAll.filter((zone) => zone.id === editZone.type)[0],
            )
            setName(editZone.name)
            setAddress(editZone.address)
            setMarkerPosition([editZone.longitude, editZone.latitude])
            setMapCenter([editZone.longitude, editZone.latitude])
        } else {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords
                    setMarkerPosition([longitude, latitude])
                    setMapCenter([longitude, latitude])
                },
                (error) => {
                    toast.error(`Error getting user location: ${error.message}`)
                },
            )
        }
    }, [editZone])

    const reverseGeocode = () => {
        fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${markerPosition[0]},${markerPosition[1]}.json?access_token=${mapBoxToken}`,
        )
            .then(async (response) => response.json())
            .then((data) => {
                if (data.features.length > 0) {
                    setAddress(data.features[0].place_name)
                } else {
                    toast.error('Address not found')
                }
            })
            .catch((error) => console.error('Ошибка:', error))
    }

    const forwardGeocode = (address: string) => {
        fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
                address,
            )}.json?access_token=${mapBoxToken}`,
        )
            .then(async (response) => response.json())
            .then((data) => {
                if (data.features.length > 0) {
                    const [lng, lat] = data.features[0].center
                    setMarkerPosition([lng, lat])
                    markerRef.current?.setLngLat([lng, lat])
                    console.log(`Координаты: ${lng}, ${lat}`)
                } else {
                    toast.error('Address not found')
                }
            })
            .catch((error) => console.error('Ошибка:', error))
    }
    const closeHandler = () => {
        setEditZone(null)
        onClose()
    }
    console.log(selectedZone)
    const submitHandler = async () => {
        if (name.trim() === '') {
            toast.error('Zone Name is required')
            return
        }
        try {
            const formData = {
                name: name,
                type: selectedZone.id,
                address: address,
                latitude: markerPosition[1],
                longitude: markerPosition[0],
                radius: range,
            }
            let res = null
            if (editZone) {
                res = await updateSaveZone({
                    id: editZone.id,
                    body: formData,
                }).unwrap()
            } else {
                res = await createSaveZone(formData).unwrap()
            }
            if (res.success) {
                closeHandler()
            } else {
                toast.error(res?.message || 'Something went wrong')
            }
        } catch (error) {
            console.error('rejected', error)
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={closeHandler}>
            <div
                className="modal__header-map"
                style={{
                    height: '300px',
                }}
            >
                <div
                    className="map"
                    ref={mapContainerRef}
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                ></div>
            </div>
            <div className="modal__body">
                <h2 className="mb-32">
                    {editZone ? 'Edit Safe Zone' : 'Add Safe Zone'}
                </h2>
                <div className="row">
                    <div className="col-md-12 col-12 mb-22">
                        <div className="flex items-center gap-22">
                            <Dropdown
                                button={
                                    <>
                                        <div className="safe-zone__box-icon">
                                            {selectedZone.icon}
                                        </div>
                                        <div className="safe-zone__edit-btn">
                                            <PanIcon />
                                        </div>
                                    </>
                                }
                                wrapperClassNames={'safe-zone'}
                                buttonClassName={'btn safe-zone__btn'}
                                menu={
                                    <DropdownMenu
                                        confirmChange={(zone) =>
                                            setSelectedZone(zone)
                                        }
                                        selectedZone={selectedZone}
                                    />
                                }
                            />
                            <div className="form-group input--lg w-full">
                                <Input
                                    label={'Safe zone name'}
                                    placeholder="Enter safe zone name..."
                                    inputType="input--outline"
                                    value={name}
                                    onChange={({ target }) => {
                                        setName(target.value)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-12 mb-22">
                        <div className="form-group input--lg">
                            <Input
                                label={'Address'}
                                placeholder="Enter your address..."
                                inputType="input--outline"
                                value={address}
                                onKeyUp={({ code, target }) => {
                                    if (code === 'Enter') {
                                        forwardGeocode(
                                            (target as HTMLInputElement).value,
                                        )
                                    }
                                }}
                                onChange={({ target }) => {
                                    setAddress(target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 col-12 mb-22">
                        <div className="form-group input--icon-right input--lg">
                            <label className="label">Set range</label>
                            <div className="row-group gap--lg">
                                <div
                                    className={clsx('calculator', {
                                        '--null': range === 0,
                                    })}
                                    data-calculator
                                >
                                    <button
                                        onClick={() =>
                                            setRange((prev) => prev + 1)
                                        }
                                        type="button"
                                        className="btn btn-calculator btn--square btn-calc--plus btn--outline-default rounded-full"
                                    >
                                        <span className="ico">
                                            <PlusIcon />
                                        </span>
                                    </button>
                                    <input
                                        type="text"
                                        value={range}
                                        data-start="0"
                                        data-calculator--input=""
                                        className="input"
                                        readOnly
                                        style={{ width: 55 }}
                                    />
                                    <button
                                        onClick={() =>
                                            setRange((prev) =>
                                                Math.max(0, prev - 1),
                                            )
                                        }
                                        type="button"
                                        className="btn btn-calculator btn--square btn-calc--minus btn--outline-default rounded-full"
                                    >
                                        <span className="ico">
                                            <MinusIcon />
                                        </span>
                                    </button>
                                </div>
                                <span className="color-text">Miles</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal__footer">
                <button
                    type="button"
                    onClick={closeHandler}
                    className="btn btn--secondary-light btn--lg rounded-full w-full"
                >
                    Cancel
                </button>
                <button
                    onClick={submitHandler}
                    className="btn btn--primary btn--lg rounded-full w-full"
                >
                    {editZone ? 'Save Changes' : 'Add Safe Zone'}
                </button>
            </div>
        </Modal>
    )
}

export default AddSaveZone

const DropdownMenu = ({
    confirmChange,
    selectedZone,
}: {
    confirmChange: (zone: SaveZoneType) => void
    selectedZone: SaveZoneType
}) => {
    const [currentChange, setCurrentChange] =
        useState<SaveZoneType>(selectedZone)
    return (
        <div className="safe-zone__icons-wrap">
            <div className="row g-8 mb-16">
                {saveZonesAll.map((zone, index) => (
                    <div className="col-3" key={index}>
                        <label className="safe-zone__icon-item">
                            <input
                                type="radio"
                                name="safe-zone"
                                className="hidden"
                                defaultChecked={currentChange.id === zone.id}
                                onChange={() => {
                                    setCurrentChange(zone)
                                }}
                            />
                            <div className="safe-zone__icon">{zone.icon}</div>
                        </label>
                    </div>
                ))}
            </div>
            <button
                type="button"
                onClick={() => confirmChange(currentChange)}
                className="btn btn--primary btn--xs radius-full w-full"
            >
                Confirm
            </button>
        </div>
    )
}
