import React from 'react'

import { handleImageError } from '../utils/helpers'

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    fallbackSrc?: string
}

const Image: React.FC<ImageProps> = ({ fallbackSrc, ...props }) => {
    return (
        <img
            {...props}
            onError={(e) => handleImageError(e, fallbackSrc)}
            alt={props.alt ?? 'not specified'}
        />
    )
}

export default Image
