import clsx from 'clsx'
import type { InputHTMLAttributes } from 'react'
import React from 'react'

export enum CheckboxSizes {
    xxs = 'check--xxs',
    sm = 'check--sm',
}

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    text: string | React.ReactNode
    boxSize?: CheckboxSizes
}

const Checkbox: React.FC<CheckboxProps> = ({
    text,
    size = CheckboxSizes.xxs,
    ...rest
}) => {
    return (
        <label
            className={clsx(
                'check-form w-full btn btn--xs justify-center check-simulate',
                size,
            )}
        >
            <input type="checkbox" hidden {...rest} />
            <div className="check-row">
                <span className="check-control check-control--custom"></span>
                <div className="check-content my-auto">
                    <p className="check-text text--md">{text}</p>
                </div>
            </div>
        </label>
    )
}

export default Checkbox
