import clsx from 'clsx'
import type { InputHTMLAttributes } from 'react'
import React, { useState, forwardRef } from 'react'
import { Link } from 'react-router-dom'

import CloseEyeIcon from '../assets/img/ico-close-eye.svg?react'
import OpenEyeIcon from '../assets/img/ico-open-eye.svg?react'

interface PasswordInputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string
    error?: string
    forgotPassword?: React.ReactNode
}

const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
    ({ label, error, forgotPassword, ...rest }, ref) => {
        const [showPassword, setShowPassword] = useState<boolean>(false)

        return (
            <div className="form-group input--icon-right input--lg">
                <label className="form-label">
                    <div className="label--text">
                        <span>{label}</span>
                    </div>
                    {forgotPassword}
                </label>
                <div className="input-wrapper">
                    <input
                        className={clsx(
                            'input input--solid w-full',
                            { 'input-error': error },
                            rest.className,
                        )}
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Enter your password..."
                        ref={ref} // Передаем ref сюда
                        {...rest}
                    />
                    <button
                        type="button"
                        className="btn btn--password"
                        onClick={() => setShowPassword((prev) => !prev)}
                    >
                        {showPassword ? <OpenEyeIcon /> : <CloseEyeIcon />}
                    </button>
                </div>
                {error && <p className="error-message">{error}</p>}
            </div>
        )
    },
)

PasswordInput.displayName = 'PasswordInput' // Установка displayName для отладки

export default PasswordInput
