import { createSlice } from '@reduxjs/toolkit'

import { alertsApi } from '../../services/alerts'
import type { IAlert } from '../../types'

interface AlertsState {
    alerts: IAlert[]
    notificationCount: number
}
const initialState = {
    alerts: [],
    notificationCount: 0,
} satisfies AlertsState as AlertsState

const alertsSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        setNotificationCount: (state, action) => {
            console.log(action.payload)
            state.notificationCount = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            alertsApi.endpoints.getAllAlerts.matchFulfilled,
            (state, { payload }) => {
                state.alerts = payload
            },
        )
        builder.addMatcher(
            alertsApi.endpoints.createAlert.matchFulfilled,
            (state, { payload }) => {
                state.alerts.push(payload)
            },
        )
        builder.addMatcher(
            alertsApi.endpoints.deleteAlert.matchFulfilled,
            (state, { payload }) => {
                state.alerts = state.alerts.filter(
                    (alert) => alert.id !== payload.id,
                )
            },
        )
    },
})

export const { setNotificationCount } = alertsSlice.actions

export default alertsSlice.reducer
