import { Offenders } from './components/Offenders'
import SidebarFilters from './components/SidebarFilters'

export enum SearchFilters {
    STATES = 'states',
    ZIPCODES = 'zipcodes',
    RANGE = 'range',
}

const HomePage = () => {
    return (
        <main className="content">
            <div className="container h-full">
                <div className="catalog-page section-fullscreen headers-similars">
                    <SidebarFilters />
                    <Offenders />
                </div>
            </div>
        </main>
    )
}

export default HomePage
