import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { InferType } from 'yup'
import { object, ref, string } from 'yup'

import type { StepsProps } from '..'
import Button from '../../../compoments/Button'
import Checkbox, { CheckboxSizes } from '../../../compoments/Checkbox'
import FormHeader from '../../../compoments/FormHeader'
import Input from '../../../compoments/Input'
import PasswordInput from '../../../compoments/PasswordInput'
import type { FormContextValue } from '../../../layouts/FormLayout'
import {
    useChangePasswordMutation,
    useRequestRecoveryMutation,
} from '../../../services/auth'

const schema = object({
    password: string()
        .required('Password is required')
        // .matches(
        //   passwordRegex,
        //   'Password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, one number, and one special character',
        // ),
        .min(8, 'Password must be at least 8 characters long'),
    confirmPassword: string()
        .required('Confirm Password is required')
        .oneOf([ref('password'), null], 'Passwords must match'),
})
type FormType = InferType<typeof schema>
interface Step4Props extends StepsProps {
    email: string
    code: number
}

const Step4: React.FC<Step4Props> = ({ setStep, email, code }) => {
    const [resetStep, setResetStep] = useState<number>(1)
    console.log(email, code)
    // const { formHeight, setFormHeight } = useOutletContext<FormContextValue>()
    // useEffect(() => {
    //   setFormHeight('min-h-500')
    // })

    const [changePassword, changePasswordOptions] = useChangePasswordMutation()
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm<FormType>({
        resolver: yupResolver(schema),
    })
    const navigate = useNavigate()
    const onSubmit = async (data: FormType) => {
        try {
            const resp = await changePassword({
                code,
                email,
                password: data.password,
            }).unwrap()
            console.log('fulfilled', resp)
            if (!resp.success || !resp.token) {
                toast.error(resp.message)
                return
            }
            window.localStorage.setItem('token', resp.token)
            // toast.success(resp.message)
            navigate('/')
            // if (resp.success && resp.data) {
            //   window.localStorage.setItem('authToken', resp.data.token)
            //   toast.success('Login successfully!')
            //   navigate('/')
            // } else {
            //   toast.error(resp?.message)
            // }
        } catch (error) {
            console.error('rejected', error)
        }
    }
    return (
        <>
            <FormHeader
                hasArrow
                handleBack={() => setStep(1)}
                heading="Reset password"
                description="Must be at least 8 characters"
            />
            <div className="form-body">
                <div className="row">
                    <>
                        <div className="col-md-12 col-12 mb-22">
                            <PasswordInput
                                label="Password"
                                error={errors.password?.message}
                                {...register('password')}
                            />
                        </div>

                        <div className="col-md-12 col-12 mb-28">
                            <PasswordInput
                                label="Confirm password"
                                error={errors.confirmPassword?.message}
                                {...register('confirmPassword')}
                            />
                        </div>
                    </>
                </div>
            </div>

            <div className="form-footer">
                <div className="row">
                    <div className="col-12">
                        <Button type="button" onClick={handleSubmit(onSubmit)}>
                            Set New Password
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Step4
