import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import TrashIcon from '../../../assets/img/ico-trash.svg?react'
import avatar from '../../../assets/img/photo-user-1.png'
import { useGetMeQuery } from '../../../services/auth'
import { useDeleteUserMutation } from '../../../services/notifications'
import type { IAlert } from '../../../types'
import { getExternalMediaUrl } from '../../../utils/helpers'

import EmptyAlerts from './EmptyAlerts'
import Table from './Table'

interface NameAlertsTableProps {
    alerts: any[]
    onDelete: (id: number) => void
}

const NameAlertsTable: React.FC<NameAlertsTableProps> = ({
    alerts,
    onDelete,
}) => {
    const { t } = useTranslation('alerts')
    const [triggerDeleteUser] = useDeleteUserMutation()
    const { refetch } = useGetMeQuery()
    const [isLoadingReq, setIsLoadingReq] = useState(false)

    const deleteUser = async (id: string) => {
        try {
            setIsLoadingReq(true)

            const res = await triggerDeleteUser({
                offenderDocIds: [id],
            }).unwrap()

            if (res.success) {
                toast.success('User deleted successfully.')
                await refetch()
            } else {
                toast.error('User not deleted successfully.')
            }
        } catch (e) {
        } finally {
            setIsLoadingReq(false)
        }
    }

    if (!alerts.length) {
        return <EmptyAlerts />
    }

    return (
        <Table columns={[t('table_name'), t('table_address'), '']}>
            {alerts.map((alert) => (
                <tr key={alert.id}>
                    <td>
                        <div className="flex items-center gap--md">
                            <div className="ico ico--md ico-user rounded-full">
                                <img
                                    src={getExternalMediaUrl(alert.image_src)}
                                    alt="user_avatar"
                                />
                            </div>
                            <p className="text--md font-600">
                                {alert.offenderName}
                            </p>
                        </div>
                    </td>
                    <td>{alert.address[0].street}</td>
                    <td>
                        <div className="flex-group gap--xs justify-end">
                            <button
                                disabled={isLoadingReq}
                                className="btn btn--outline-danger btn--md rounded-full"
                                onClick={async () => deleteUser(alert.id)}
                            >
                                <span className="ico">
                                    <TrashIcon />
                                </span>
                                <p className="info">{t('table_delete')}</p>
                            </button>
                        </div>
                    </td>
                </tr>
            ))}
        </Table>
    )
}

export default NameAlertsTable
