import React from 'react'

import UpdatePremiumModal from '../UpdatePremiumModal'

import type { ModalType } from './ModalProvider'

interface ModalContainerProps {
    modal: { type: ModalType; props?: Record<string, unknown> }
    hideModal: () => void
}

const ModalContainer: React.FC<ModalContainerProps> = ({
    modal,
    hideModal,
}) => {
    const { type, props } = modal

    switch (type) {
        case 'upgrade':
            return (
                <UpdatePremiumModal
                    {...props}
                    onClose={hideModal}
                    isOpen={true}
                />
            )
        default:
            return null
    }
}

export default ModalContainer
