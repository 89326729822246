import React from 'react'

import Spinner from './Spinner'

export const PageLoader = () => {
    return (
        <main className="content">
            <div className="container h-full">
                <div className="flex items-center justify-center h-full">
                    <Spinner />
                </div>
            </div>
        </main>
    )
}
