import type { IAlert } from '../types'

import { baseApi } from './base'
import type { IResponseType } from './types'

const alertsBase = 'api/alerts'

export const alertsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllAlerts: builder.query<IAlert[], void>({
            query: () => `${alertsBase}/all`,
        }),
        createAlert: builder.mutation<IResponseType<IAlert>, IAlert>({
            query: (body) => ({
                url: `${alertsBase}/`,
                method: 'POST',
                body,
            }),
        }),
        deleteAlert: builder.mutation<IResponseType<IAlert>, string>({
            query: (id) => ({
                url: `${alertsBase}/${id}`,
                method: 'DELETE',
            }),
        }),
    }),
})

export const {
    useGetAllAlertsQuery,
    useCreateAlertMutation,
    useDeleteAlertMutation,
} = alertsApi
