import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import PasswordInput from '../../compoments/PasswordInput'
import { useGetMeQuery, useUpdateMeMutation } from '../../services/auth'
import { changePasswordSchema } from '../../shemas/validationSchema'

enum PasswordEnum {
    oldPassword = 'oldPassword',
    newPassword = 'password',
    newPasswordConf = 'confirmPassword',
}

type PasswordType = {
    [PasswordEnum.oldPassword]: string
    [PasswordEnum.newPassword]: string
    [PasswordEnum.newPasswordConf]: string
}

export const SecurityPage: React.FC = () => {
    const { t } = useTranslation('settings')
    const [triggerUpdateMe] = useUpdateMeMutation()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(changePasswordSchema),
    })
    const [isRequestSend, setIsRequestSend] = useState(false)

    const [fields, setFields] = useState([
        {
            name: t('password.old'),
            isShow: false,
            identifier: PasswordEnum.oldPassword,
        },
        {
            name: t('password.new'),
            isShow: false,
            identifier: PasswordEnum.newPassword,
        },
        {
            name: t('password.confirm'),
            isShow: false,
            identifier: PasswordEnum.newPasswordConf,
        },
    ])

    const onSubmit = async (data: PasswordType) => {
        const formData = new FormData()

        formData.append('password', data.password)
        formData.append('oldPassword', data.oldPassword)

        try {
            setIsRequestSend(true)
            const res = await triggerUpdateMe(formData).unwrap()

            if (res.success) {
                toast.success('Password updated successfully', {
                    autoClose: 3000,
                })
            } else {
                toast.error(res.message, {
                    autoClose: 3000,
                })
            }
        } catch (e) {
            toast.error('Password not updated', {
                autoClose: 3000,
            })
            console.error('Error during registration:', e)
        } finally {
            setIsRequestSend(false)
        }
    }

    return (
        <div className="tab active-tab" data-id="tab_password-security">
            <div className="settings__header">
                <h2 className="fw-500">{t('password.title')}</h2>
                <button
                    disabled={isRequestSend}
                    onClick={handleSubmit(onSubmit)}
                    className="btn btn--primary btn--sm radius-full"
                >
                    {t('password.change')}
                </button>
            </div>
            <div className="settings__body-content">
                <div className="max-w-450">
                    {fields.map((el, idx) => (
                        <div
                            key={idx}
                            className="form-group input--icon-right input--lg mb-22"
                        >
                            <PasswordInput
                                label={el.name}
                                {...register(el.identifier)}
                                error={errors?.[el.identifier]?.message}
                                placeholder={t('password.placeholder')}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
