/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import ActiveIcon from '../../../assets/img/active-user-icon.svg?react'
import EditIcon from '../../../assets/img/edit-user-icon.svg?react'
import PluseIcon from '../../../assets/img/pluse-icon.svg?react'
import TrashIcon from '../../../assets/img/trashIcon.svg?react'
import Image from '../../../compoments/Image'
import { FAMILY_STATUS } from '../../../constants/statuses'
import { getIsPremium, getUser } from '../../../features/auth/authSlice'
import { useAppSelector } from '../../../hooks/redux'
import { useDeleteFamilyMemberMutation } from '../../../services/family'
import type { IFamilyMember } from '../../../types'
import { getMediaUrl, timeAgo } from '../../../utils/helpers'

import AddFamilyMember from './AddFamilyMember'
import type { IFamilySidebar } from './FamilySidebar'
const FamilyTab = ({
    users,
    selectedUser,
    setSelectedUser,
}: Omit<IFamilySidebar, 'saveZones'>) => {
    const { t } = useTranslation('members')
    const me = useAppSelector(getUser)

    const [addFamilyModal, setAddFamilyModal] = useState<boolean>(false)
    const [editUser, setEditUser] = useState<IFamilyMember | null>(null)
    const [deleteMember] = useDeleteFamilyMemberMutation()
    const familyModalHandler = () => {
        setAddFamilyModal((prev) => !prev)
    }
    const removeMemberHandler = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        id: number,
    ) => {
        e.stopPropagation()
        try {
            const res = await deleteMember(id).unwrap()
            if (res.success) {
                toast.success(res?.message || 'Family Member deleted')
            } else {
                toast.error(res?.message || 'Something went wrong')
            }
        } catch (error) {
            console.error('rejected', error)
        }
    }

    return (
        <>
            <div className="tab mb-20 active-tab">
                <ul className="family-list mb-32">
                    {users.map((user, index) => (
                        <li className="family-list__item" key={index}>
                            <div
                                className={clsx(
                                    'btn family-link w-full family-link-map-veiw',
                                    {
                                        active: selectedUser?.id === user.id,
                                    },
                                )}
                                onClick={() => {
                                    if (user.status !== FAMILY_STATUS.Accepted)
                                        return
                                    if (selectedUser?.id === user.id) {
                                        setSelectedUser(null!)
                                    } else {
                                        setSelectedUser(user)
                                    }
                                }}
                            >
                                <div className="ico ico--sm family-link__icon rounded-full relative overflow-hidden">
                                    <Image src={getMediaUrl(user.photo)} />
                                    {selectedUser?.id === user.id && (
                                        <span className="active-icon">
                                            <ActiveIcon />
                                        </span>
                                    )}
                                </div>
                                <div className="family-link__text flex-auto">
                                    <div className="family-link__title">
                                        {user.fullName}
                                    </div>
                                    <div
                                        className={clsx('family-link__text', {
                                            '--status':
                                                user.status !==
                                                FAMILY_STATUS.Accepted,
                                        })}
                                    >
                                        {user.status === FAMILY_STATUS.Accepted
                                            ? `${t('sidebar.last_activity')}: ${timeAgo(user.member?.lastSeen as string, me?.language)}`
                                            : t('sidebar.waiting_approval')}
                                    </div>
                                </div>
                                <div className="family-link__actions">
                                    <button
                                        type="button"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setEditUser(user)
                                            familyModalHandler()
                                        }}
                                        className="btn"
                                    >
                                        <span className="ico">
                                            <EditIcon />
                                        </span>
                                    </button>
                                    <button
                                        onClick={async (e) =>
                                            removeMemberHandler(e, user.id)
                                        }
                                        className="btn color-danger"
                                    >
                                        <span className="ico">
                                            <TrashIcon />
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                {!me?.familyMember && (
                    <button
                        type="button"
                        onClick={familyModalHandler}
                        className="btn btn--primary btn--lg rounded-full w-full"
                    >
                        <span className="ico">
                            <PluseIcon />
                        </span>
                        <span>{t('sidebar.add_family')}</span>
                    </button>
                )}
            </div>
            {addFamilyModal && (
                <AddFamilyMember
                    isOpen={addFamilyModal}
                    onClose={familyModalHandler}
                    editUser={editUser}
                    setEditUser={setEditUser}
                />
            )}
        </>
    )
}

export default FamilyTab
