/* eslint-disable jsx-a11y/label-has-associated-control */
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import NotificationPrimaryIcon from '../../../assets/img/ico-notification-1.svg?react'
import NotificationSecondaryIcon from '../../../assets/img/ico-notification-2.svg?react'
import Button from '../../../compoments/Button'
import Image from '../../../compoments/Image'
import Modal from '../../../compoments/Modal'
import { getIsExpired, getIsPremium } from '../../../features/auth/authSlice'
import { useAppSelector } from '../../../hooks/redux'
import { useModal } from '../../../hooks/useModal'
import { useGetMeQuery } from '../../../services/auth'
import { useAddUserMutation, useDeleteUserMutation } from '../../../services/notifications'
import { useLazyGetArestInformationsQuery } from '../../../services/users'
import type { IOffender } from '../../../types'
import { getExternalMediaUrl, isValidDate } from '../../../utils/helpers'

interface OffenderPopupProps {
    data: IOffender
    isOpen: boolean
    onClose: () => void
}

const OffenderPopup = ({ data, isOpen, onClose }: OffenderPopupProps) => {
    const { data: userData, refetch } = useGetMeQuery()
    const [triggerAddUser] = useAddUserMutation()
    const [triggerDeleteUser] = useDeleteUserMutation()
    const isPremium = useAppSelector(getIsPremium)
    const isExpired = useAppSelector(getIsExpired)

    const [getArestInformations, { data: arestData, isLoading }] =
        useLazyGetArestInformationsQuery()
    const { showModal } = useModal()
    const [showArest, setShowArest] = useState(false)
    const dispatch = useDispatch()
    const [isNotify, setIsNotify] = useState(false)
    const selectedOffenders = userData?.data?.offenders || []
    const { t } = useTranslation('offender_popup')

    useEffect(() => {
        if (selectedOffenders.includes(data?.id as string)) {
            setIsNotify(true)
        } else {
            setIsNotify(false)
        }
    }, [selectedOffenders])

    const handleChange = async () => {
        if (!data?.id) return

        try {
            setIsNotify(true)
            let res 
            if(isNotify){
                res = await triggerDeleteUser({
                    offenderDocIds: [data.id],
                }).unwrap()
            } else {
                res = await triggerAddUser({
                    offenderDocIds: [data.id],
                }).unwrap()
            }
            

            if (res.success) {
                // toast.success('Offender Added Successfully')
                await refetch()
            } else {
                setIsNotify(false)
                toast.error(
                    res.message || 'Offender notifications added successfully',
                )
            }
        } catch (e) {
            setIsNotify(false)
            toast.error('Please Login / Sign Up')
        }
    }
    const handleOpenUpgrade = () => {
        showModal('upgrade')
    }
    const handleOpenArest = async (id: number) => {
        try {
            const res = await getArestInformations(id)
            if (!res.data?.success) {
                toast.error('User not arests information')
            } else {
                setShowArest(true)
            }
        } catch (e) {
            toast.error('Something went wrong')
        }
    }
    const address = data?.address?.[0]

    if (!data) return null
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            modalClassNames={'modal-card'}
            modalSize={'modal__dialog--410'}
        >
            <div className="modal__header">
                <div className="user-avatar-group">
                    <div className="user-avatar-72x72">
                        <Image
                            src={getExternalMediaUrl(data.image_src)}
                            alt="card-photo"
                        />
                    </div>
                    <div className="user-info-col">
                        <span className="user-info__name">
                            {data.offenderName}
                        </span>
                        <span className="user-info__address">
                            {address?.street}
                        </span>
                    </div>
                </div>
                <label className="btn">
                    <input
                        // disabled={isNotify}
                        type="checkbox"
                        hidden
                        checked={isNotify}
                        onChange={handleChange}
                    />
                    <div className="btn btn--square btn--md rounded-full btn-check--likely">
                        <span className="ico">
                            <NotificationPrimaryIcon />
                        </span>
                        <span className="ico">
                            <NotificationSecondaryIcon />
                        </span>
                    </div>
                </label>
            </div>
            <div className="modal__body">
                {!isExpired && (
                    <div className="form-group gap-10 mb-22">
                        <label className="label">{t('mugshots')}</label>
                        <div className="mugshots-group">
                            <div className="mugshot-item">
                                <Image
                                    src={getExternalMediaUrl(data.image_src)}
                                    alt={data.offenderName}
                                />
                            </div>
                            {/* <div className="mugshot-item">
                <Image
                  src={getMediaUrl(data.image_src)}
                  alt={data.offenderName}
                />
              </div> */}
                        </div>
                    </div>
                )}
                {!showArest && (
                    <div className="form-group gap-10 mb-22">
                        <label className="label">{t('age')}</label>
                        <div className="info-block">
                            {data.age || 'Not Specified'}
                        </div>
                    </div>
                )}
                {!isExpired ? (
                    <>
                        {showArest ? (
                            <>
                                <div className="form-group gap-10 mb-22">
                                    <label className="label">{t('dob')}</label>
                                    <div className="info-block">
                                        {arestData?.dob &&
                                        isValidDate(arestData?.dob)
                                            ? format(
                                                  arestData?.dob,
                                                  'MM/dd/yyyy',
                                              )
                                            : 'Not Specified'}
                                    </div>
                                </div>
                                <div className="form-group gap-10 mb-22">
                                    <label className="label">{t('link')}</label>
                                    {arestData?.link ? (
                                        <a
                                            href={arestData?.link || '#'}
                                            style={{
                                                wordWrap: 'break-word',
                                                textDecoration: 'underline',
                                            }}
                                            target="_blank"
                                            className="info-block"
                                        >
                                            arestData?.link
                                        </a>
                                    ) : (
                                        <div className="info-block">
                                            Not Specified
                                        </div>
                                    )}
                                </div>
                                <div className="form-group gap-10 mb-22">
                                    <label className="label">
                                        {t('height')}
                                    </label>
                                    <div className="info-block">
                                        {arestData?.height || 'Not Specified'}
                                    </div>
                                </div>
                                <div className="form-group gap-10 mb-22">
                                    <label className="label">{t('sex')}</label>
                                    <div className="info-block">
                                        {arestData?.sex || 'Not Specified'}
                                    </div>
                                </div>
                                <div className="form-group gap-10 mb-22">
                                    <label className="label">
                                        {t('weight')}
                                    </label>
                                    <div className="info-block">
                                        {arestData?.weight || 'Not Specified'}
                                    </div>
                                </div>
                                <div className="form-group gap-10 mb-22">
                                    <label className="label">Modify Date</label>
                                    <div className="info-block">
                                        {arestData?.modifyDate
                                            ? format(
                                                  arestData?.modifyDate,
                                                  'MM/dd/yyyy',
                                              )
                                            : arestData?.createDate
                                              ? format(
                                                    arestData?.createDate,
                                                    'MM/dd/yyyy',
                                                )
                                              : 'Not Specified'}
                                    </div>
                                </div>
                                <div className="form-group gap-10 mb-22">
                                    <label className="label">
                                        {t('charges')}
                                    </label>

                                    {arestData?.charges?.length ? (
                                        arestData?.charges?.map(
                                            (charge, index) => (
                                                <div
                                                    className="info-block"
                                                    key={index}
                                                >
                                                    <p>
                                                        <span className="font-bold">
                                                            {t('charge')}:
                                                        </span>{' '}
                                                        {charge.charge}
                                                    </p>
                                                    <p>
                                                        <span className="font-bold">
                                                            {t(
                                                                'charge_details',
                                                            )}
                                                            :
                                                        </span>{' '}
                                                        {charge.charge_details}
                                                    </p>
                                                    <p>
                                                        <span className="font-bold">
                                                            {t(
                                                                'date_convicted',
                                                            )}
                                                            :
                                                        </span>{' '}
                                                        {charge.date_convicted}
                                                    </p>
                                                    <p>
                                                        <span className="font-bold">
                                                            {t('victim_age')}:
                                                        </span>{' '}
                                                        {charge.victim_age}
                                                    </p>
                                                    <p>
                                                        <span className="font-bold">
                                                            {t('court')}:
                                                        </span>{' '}
                                                        {charge.court}
                                                    </p>
                                                    <p>
                                                        <span className="font-bold">
                                                            {t('modify_date')}:
                                                        </span>{' '}
                                                        {charge?.modifyDate
                                                            ? format(
                                                                  charge?.modifyDate,
                                                                  'MM/dd/yyyy',
                                                              )
                                                            : charge?.createDate
                                                              ? format(
                                                                    charge?.createDate,
                                                                    'MM/dd/yyyy',
                                                                )
                                                              : 'Not Specified'}
                                                    </p>
                                                </div>
                                            ),
                                        )
                                    ) : (
                                        <div className="info-block">
                                            'Not Specified'
                                        </div>
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="form-group gap-10 mb-22">
                                    <label className="label">
                                        {t('addresses')}
                                    </label>
                                    <div className="info-block">
                                        {data.address?.length
                                            ? data.address.map(
                                                  ({ street }) => street,
                                              )
                                            : 'Not Specified'}
                                    </div>
                                </div>
                                <div className="form-group gap-10 mb-22">
                                    <label className="label">
                                        {t('charges')}
                                    </label>
                                    <div className="info-block">
                                        {/* Assault and Battery
                <br />
                Vandalism and Trespassing */}
                                        Not Specified
                                    </div>
                                </div>
                                <div className="form-group gap-10">
                                    <label className="label">
                                        {t('aliases')}
                                    </label>
                                    <div className="info-block">
                                        {data.aliases || 'Not Specified'}
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <p className="modal-card__description">
                        {t('desc_1')}
                        <br /> {t('desc_2')}
                    </p>
                )}
            </div>
            <div className="modal__footer">
                {!isExpired ? (
                    <>
                        {showArest ? (
                            <Button
                                isLoading={isLoading}
                                onClick={() => setShowArest(false)}
                            >
                                <span>Hide Information</span>
                            </Button>
                        ) : (
                            <Button
                                isLoading={isLoading}
                                onClick={async () => handleOpenArest(+data.id)}
                            >
                                <span>{t('arrests_information')}</span>
                            </Button>
                        )}
                    </>
                ) : (
                    <button
                        onClick={handleOpenUpgrade}
                        type="button"
                        className="btn btn--lg  btn--gradient rounded-full"
                    >
                        <span className="ico">
                            <svg
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M19.8083 12.4319L20.0191 10.1989C20.1841 8.44621 20.2666 7.56896 19.9659 7.20687C19.8901 7.11277 19.7963 7.03461 19.6901 6.97687C19.584 6.91913 19.4674 6.88297 19.3472 6.87046C18.9108 6.83196 18.3636 7.45529 17.2682 8.70196C16.7017 9.34729 16.4184 9.66904 16.1031 9.71946C15.927 9.7471 15.7466 9.71822 15.5879 9.63696C15.2955 9.49029 15.1021 9.09154 14.7125 8.29496L12.6619 4.09296C11.9268 2.58687 11.5592 1.83337 11 1.83337C10.4408 1.83337 10.0733 2.58687 9.33809 4.09296L7.2875 8.29496C6.89884 9.09246 6.7045 9.49029 6.41209 9.63696C6.25321 9.71762 6.07304 9.74647 5.89692 9.71946C5.58159 9.66904 5.29834 9.34729 4.73184 8.70196C3.63642 7.45529 3.08917 6.83196 2.65284 6.87046C2.53261 6.88297 2.41605 6.91913 2.30986 6.97687C2.20367 7.03461 2.10995 7.11277 2.03409 7.20687C1.73434 7.56896 1.81684 8.44621 1.98184 10.1989L2.19175 12.4319C2.53825 16.1095 2.71059 17.9493 3.79592 19.0575C4.8785 20.1667 6.50284 20.1667 9.75334 20.1667H12.2458C15.4953 20.1667 17.1197 20.1667 18.2041 19.0575C19.2885 17.9484 19.4618 16.1095 19.8083 12.4319Z"
                                    fill="currentColor"
                                ></path>
                            </svg>
                        </span>
                        <span>Upgrade to Premium</span>
                    </button>
                )}
            </div>
        </Modal>
    )
}

export default OffenderPopup
