import React from 'react'

import DownloadAppButton from './DownloadAppButton'

const DownloadAppButtonGroup = () => {
    return (
        <div className="row-group gap--xs mt-auto">
            <DownloadAppButton name="ios" />
            <DownloadAppButton name="android" />
        </div>
    )
}

export default DownloadAppButtonGroup
