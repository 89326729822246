import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useGetMeQuery, useUpdateMeMutation } from '../../services/auth'

export const NotificationPage: React.FC = () => {
    const { t } = useTranslation('settings')
    const notifications = [
        {
            id: 1,
            name: t('notifications.update_by_zip'),
            isChecked: false,
            identifier: 'update_by_zip',
        },
        {
            id: 2,
            name: t('notifications.update_by_name'),
            isChecked: false,
            identifier: 'update_by_name',
        },
        {
            id: 3,
            name: t('notifications.update_safe_zone'),
            isChecked: false,
            identifier: 'update_safe_zone',
        },
        // {
        //   id: 3,
        //   name: 'New person in my area',
        //   isChecked: false,
        // },
    ]

    const { data: dataUser, refetch } = useGetMeQuery()

    const [triggerUpdateMe] = useUpdateMeMutation()

    const [isRequestSend, setIsRequestSend] = useState(false)
    const [notificationsData, setNotificationsData] = useState(notifications)

    useEffect(() => {
        if (!dataUser?.data) return

        const not = dataUser.data.notifications

        setNotificationsData((prev) =>
            prev.map((el) => {
                if (not.includes(el.identifier)) {
                    return {
                        ...el,
                        isChecked: true,
                    }
                } else {
                    return el
                }
            }),
        )
    }, [dataUser?.data])

    const changeChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
        const id = e.target.getAttribute('data-el') as String
        const check = e.currentTarget.checked
        setNotificationsData((prev) =>
            prev.map((el) => {
                if (el.id === +id) {
                    return {
                        ...el,
                        isChecked: check,
                    }
                } else {
                    return el
                }
            }),
        )
    }

    const onSubmit = async () => {
        const formData = new FormData()

        let notChecked = true
        console.log(notificationsData)
        notificationsData
            .filter((el) => el.isChecked)
            .forEach((el, idx) => {
                notChecked = false
                formData.append(`notifications[${idx}]`, el.identifier)
            })

        if (notChecked) {
            formData.append(`notifications`, '')
        }

        try {
            setIsRequestSend(true)
            const res = await triggerUpdateMe(formData).unwrap()

            if (res.success) {
                refetch()

                toast.success('Notifications updated successfully', {
                    autoClose: 3000,
                })
            } else {
                toast.error('Notifications not updated ', {
                    autoClose: 3000,
                })
            }
        } catch (e) {
        } finally {
            setIsRequestSend(false)
        }
    }

    return (
        <div className="tab active-tab" data-id="tab_notifications">
            <div className="settings__header">
                <h2 className="fw-500">{t('notifications.title')}</h2>
                <button
                    disabled={isRequestSend}
                    onClick={(e) => {
                        e.preventDefault()
                        onSubmit()
                    }}
                    className="btn btn--primary btn--sm radius-full"
                >
                    {t('save')}
                </button>
            </div>
            <div className="settings__body-content">
                {notificationsData.map((el) => (
                    <label key={el.id} className="switch-control mb-20">
                        <input
                            type="checkbox"
                            hidden
                            checked={el.isChecked}
                            data-el={el.id}
                            onChange={changeChecked}
                        />
                        <div className="switch-btn">
                            <div className="switch-point-wrapp">
                                <span className="switch-point"></span>
                            </div>
                        </div>
                        <p className="text--md">{el.name}</p>
                    </label>
                ))}
            </div>
        </div>
    )
}
