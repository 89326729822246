import clsx from 'clsx'
import type { InputHTMLAttributes } from 'react'
import React, { forwardRef } from 'react'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string
    error?: string
    inputType?: 'input--solid' | 'input--outline'
}

const Input = forwardRef<HTMLInputElement, InputProps>(
    ({ label, error, inputType = 'input--solid', ...rest }, ref) => {
        return (
            <div className="form-group input--lg">
                {label && (
                    <label className="form-label">
                        <div className="label--text">
                            <span>{label}</span>
                        </div>
                    </label>
                )}
                <div className="form-group">
                    <input
                        className={clsx(
                            'input input--lg  w-full',
                            inputType,
                            { 'input-error': error },
                            rest.className,
                        )}
                        {...rest}
                        ref={ref}
                    />
                </div>
                {error && <p className="error-message">{error}</p>}
            </div>
        )
    },
)

Input.displayName = 'Input'

export default Input
