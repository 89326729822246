import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'

import type { StepsProps } from '..'
import Button from '../../../compoments/Button'
import Checkbox, { CheckboxSizes } from '../../../compoments/Checkbox'
import FormHeader from '../../../compoments/FormHeader'
import PasswordInput from '../../../compoments/PasswordInput'
import { useGetMeQuery, useRegisterMutation } from '../../../services/auth'
import { passwordSchema } from '../../../shemas/validationSchema'

const Step2: React.FC<StepsProps> = ({ setStep, activeFormData }) => {
    const [registerApi, { isLoading }] = useRegisterMutation()

    const navigate = useNavigate()

    const { refetch } = useGetMeQuery()

    const [isRequestSend, setIsRequestSend] = useState(false)

    const [resError, setResError] = useState<boolean | string>(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(passwordSchema),
    })

    const onSubmit = async (data: {
        password: string
        confirmPassword: string
    }) => {
        const formData = new FormData()

        const obj: { [key: string]: string } = {
            ...data,
            ...activeFormData,
        }

        Object.entries(obj).forEach(([key, val]) => {
            formData.append(key, val)
        })

        try {
            setResError(false)
            setIsRequestSend(true)
            const res = await registerApi(formData).unwrap()

            if (res.success) {
                window.localStorage.setItem('token', res.token)

                await refetch()
                navigate('/')
            } else {
                setResError(res.message)
            }
        } catch (e) {
            setResError('Something went wrong')
            console.error('Error during registration:', e)
        } finally {
            setIsRequestSend(false)
        }
    }

    return (
        <>
            <FormHeader
                hasArrow
                isLoading={isRequestSend}
                handleBack={() => setStep(1)}
                heading="Set password"
                description="Create password for your account."
            />
            <div className="form-body">
                <div className="row">
                    <div className="col-md-12 col-12 mb-28">
                        <PasswordInput
                            label="Password"
                            {...register('password')}
                            error={errors?.password?.message}
                        />
                    </div>

                    <div className="col-md-12 col-12 mb-28">
                        <PasswordInput
                            label="Confirm password"
                            {...register('confirmPassword')}
                            error={errors?.confirmPassword?.message}
                        />
                    </div>

                    <div className="col-md-12 col-12 mb-28">
                        <Checkbox
                            boxSize={CheckboxSizes.sm}
                            text={
                                <span>
                                    By signing up, I agree with the{' '}
                                    <Link
                                        to="/"
                                        className="color-secondary font-600"
                                    >
                                        Terms of Use
                                    </Link>{' '}
                                    &{' '}
                                    <Link
                                        to="/"
                                        className="color-secondary font-600"
                                    >
                                        Privacy Policy
                                    </Link>
                                </span>
                            }
                        />
                    </div>
                </div>
                {resError && <p className="error-message mb-4">{resError}</p>}
            </div>

            <div className="form-footer">
                <div className="row">
                    <div className="col-12 mb-22">
                        <Button
                            isLoading={isLoading}
                            type="button"
                            disabled={isRequestSend}
                            onClick={handleSubmit(onSubmit)}
                        >
                            Create Account
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Step2
