import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import FilterIcon from '../../../assets/img/ico-filter.svg?react'
import DownloadAppButtonGroup from '../../../compoments/DownloadAppButtonGroup'
import { useTabs } from '../../../hooks/useTabs'
import type { IFamilyMember, ISaveZone } from '../../../types'

import FamilyTab from './FamilyTab'
import SaveZonesTab from './SaveZonesTab'

export interface IFamilySidebar {
    saveZones: ISaveZone[]
    users: IFamilyMember[]
    selectedUser: IFamilyMember | null
    setSelectedUser: (value: IFamilyMember) => void
}
const FamilySidebar = ({
    saveZones,
    users,
    selectedUser,
    setSelectedUser,
}: IFamilySidebar) => {
    const { t } = useTranslation('members')
    const tabs = [
        {
            id: 'family',
            label: t('sidebar.tab_family'),
        },
        {
            id: 'safe-zones',
            label: t('sidebar.tab_zones'),
        },
    ] as const

    const { activeTab, setActiveTab } = useTabs(tabs)
    const handleSidebarToggle = () => {
        document.querySelector('body')?.classList.toggle('--toggle-side')
    }

    return (
        <>
            <div className="catalog-page-aside--wrapper">
                <button
                    onClick={handleSidebarToggle}
                    className="btn btn--square btn--xl btn-aside side-toggler-js"
                >
                    <span className="ico">
                        <FilterIcon />
                    </span>
                </button>
                <div className="catalog-page-aside scroll-styler">
                    <div className="aside-header header-similar">
                        <div className="tabs-nav-2">
                            {tabs.map((tab) => (
                                <button
                                    key={tab.id}
                                    className={clsx(
                                        'tab-link-3 btn btn--sm rounded-full',
                                        {
                                            'active-tab': activeTab === tab.id,
                                        },
                                    )}
                                    onClick={() => setActiveTab(tab.id)}
                                >
                                    <span>{tab.label}</span>
                                    <span>
                                        {tab.id === 'family'
                                            ? users.length
                                            : saveZones.length}
                                    </span>
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="aside-body">
                        {activeTab === 'family' && (
                            <FamilyTab
                                users={users}
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                            />
                        )}
                        {activeTab === 'safe-zones' && (
                            <SaveZonesTab saveZones={saveZones} />
                        )}
                        <DownloadAppButtonGroup />
                    </div>
                </div>
            </div>
        </>
    )
}

export default FamilySidebar
