import type { Dispatch, FC, SetStateAction } from 'react'
import React, { useState } from 'react'

import Step1 from './components/Step1'
import Step2 from './components/Step2'

export enum SignUpSteps {
    ONE = 1,
    TWO = 2,
}

export interface StepsProps {
    setStep: Dispatch<SetStateAction<SignUpSteps>>
}

const StepsComponentMap: Record<SignUpSteps, FC<StepsProps>> = {
    [SignUpSteps.ONE]: Step1,
    [SignUpSteps.TWO]: Step2,
}

const LoginPage: FC = () => {
    const [step, setStep] = useState<SignUpSteps>(SignUpSteps.ONE)
    const ComponentToRender = StepsComponentMap[step]
    return <ComponentToRender setStep={setStep} />
}
export default LoginPage
