import { yupResolver } from '@hookform/resolvers/yup'
import type { FC } from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import type { StepsProps } from '..'
import Button from '../../../compoments/Button'
import FormHeader from '../../../compoments/FormHeader'
import Input from '../../../compoments/Input'
import PasswordInput from '../../../compoments/PasswordInput'
import SocialButtons from '../../../compoments/SocialButtons'
import { useGetMeQuery, useLoginMutation } from '../../../services/auth'
import { loginSchema } from '../../../shemas/validationSchema'

const Step1: FC<StepsProps> = ({ setStep }) => {
    console.log('1')
    const { t } = useTranslation('login')

    const navigate = useNavigate()

    const [loginApi, { isLoading }] = useLoginMutation()

    const { refetch, isFetching: isUserLoading } = useGetMeQuery()

    const [isRequestSend, setIsRequestSend] = useState(false)

    const [resError, setResError] = useState<boolean | string>(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(loginSchema),
    })

    const onSubmit = async (data: { email: string; password: string }) => {
        try {
            setResError(false)
            setIsRequestSend(true)
            const res = await loginApi(data).unwrap()

            if (res.success) {
                window.localStorage.setItem('token', res.data.token)

                await refetch()
                navigate('/')
            } else {
                setResError(res.message)
            }
        } catch (e) {
            setResError('Something went wrong')
            console.error('Error during login:', e)
        } finally {
            setIsRequestSend(false)
        }
    }

    return (
        <>
            <FormHeader hasLogo heading={t('title')} description={t('descr')} />
            <div className="form-body">
                <div className="row">
                    <div className="col-md-12 col-12 mb-22">
                        <Input
                            label={t('email')}
                            placeholder={t('email_placeholder')}
                            {...register('email')}
                            error={errors?.email?.message}
                        />
                    </div>
                    <div className="col-md-12 col-12 mb-28">
                        <PasswordInput
                            label={t('password')}
                            placeholder={t('password_placeholder')}
                            {...register('password')}
                            error={errors?.password?.message}
                            forgotPassword={
                                <button
                                    onClick={() => {
                                        setStep(2)
                                    }}
                                    className="color-secondary text--sm fw-500"
                                >
                                    {t('forgot')}
                                </button>
                            }
                        />
                    </div>
                    {resError && (
                        <p className="error-message mb-4">{resError}</p>
                    )}
                    <div className="col-12 mb-44">
                        <Button
                            isLoading={isLoading || isUserLoading}
                            type="button"
                            disabled={isRequestSend}
                            onClick={handleSubmit(onSubmit)}
                        >
                            {t('title')}
                        </Button>
                    </div>
                </div>
            </div>

            <div className="form-footer">
                <div className="row">
                    <div className="col-12 mb-28">
                        <SocialButtons button="call" />
                    </div>
                    <div className="col-12">
                        <div className="flex items-center justify-center gap-10">
                            <span className="text--md">
                                Don’t have an account?
                            </span>
                            <Link
                                to="/create-account"
                                className="color-secondary text--md fw-600"
                            >
                                Create an Account
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Step1
