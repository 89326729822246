import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import PlusIcon from '../../assets/img/ico-calc-plus.svg?react'
import Button from '../../compoments/Button'
import Pagination from '../../compoments/Pagination'
import {
    getCurrentSubscription,
    getIsExpired,
    getIsPremium,
} from '../../features/auth/authSlice'
import { useAppSelector } from '../../hooks/redux'
import { useTabs } from '../../hooks/useTabs'
import { useGetMeQuery } from '../../services/auth'
import { useGetProfilesUsersMutation } from '../../services/users'

import AddAlertModal from './components/AddAlertModal'
import NameAlertsTable from './components/NameAlertsTable'
import ZipCodeAlertsTable from './components/ZipCodeAlertsTable'

const AlertsPage = () => {
    const { t } = useTranslation('alerts')
    const tabs = [
        { id: 'name_alerts', label: t('name_tab') },
        { id: 'zip_codes_alerts', label: t('zip_tab') },
    ] as const

    const { activeTab, setActiveTab } = useTabs(tabs)
    const [isAddAlertModalOpen, setIsAddAlertModalOpen] = useState(false)
    const [activeAlert, setActiveAlert] = useState(null)
    const subscription = useAppSelector(getCurrentSubscription)
    const isPremium = useAppSelector(getIsPremium)
    const isExpired = useAppSelector(getIsExpired)
    const [triggerGetUsersProfiles] = useGetProfilesUsersMutation()
    const { data: userData, refetch } = useGetMeQuery()

    // const nameAlerts = Array.from({ length: 12 }).map((_, index) => ({
    //   id: index,
    //   name: 'Bessie Cooper',
    //   address: '1901 Thornridge Cir. Shiloh, Hawaii',
    // }))

    const [nameAlerts, setNameAlerts] = useState<[] | any>([])

    const [zipCodes, setZipCodes] = useState<
        [] | { zip: string; range: number }[]
    >([])

    const req = async (arr: string[]) => {
        const res = await triggerGetUsersProfiles({
            offenderDocIds: arr,
        }).unwrap()

        if (res) {
            setNameAlerts(res.offenders)
        }
    }
    function getItemsForPage(
        items: any[],
        currentPage: number,
        itemsPerPage = 10,
    ): any[] {
        const startIndex = (currentPage - 1) * itemsPerPage
        const endIndex = startIndex + itemsPerPage
        return items.slice(startIndex, endIndex)
    }
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        if (userData?.data?.zipcodes) {
            const arr = Object.entries(userData.data.zipcodes).map(
                ([key, value]) => ({
                    zip: key as string,
                    range: value as number,
                }),
            )
            setZipCodes(arr)
        }

        if (userData?.data?.offenders) {
            req(getItemsForPage(userData?.data?.offenders, currentPage))
        }
    }, [userData?.data, currentPage])

    const editAlertZip = (alert: any) => {
        setIsAddAlertModalOpen(true)
        setActiveAlert(alert)
    }

    const alerstLeft = subscription?.alertsCount

    const canAddAlerts =
        (subscription?.alertsCount && subscription?.alertsCount > 0) ||
        !isExpired
    return (
        <main className="content">
            <div className="container h-full">
                <div className="catalog-page section-fullscreen headers-similars">
                    <div className="catalog-page-content w-full">
                        <div className="card card-border h-full">
                            <div className="card-header border-none">
                                <div className="card-header--item">
                                    <h1 className="heading font-500">
                                        {t('title')}
                                    </h1>
                                    {isExpired && (
                                        <div
                                            className="btn btn--md  btn--gradient rounded-full"
                                            style={{
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            {alerstLeft} alerts left
                                        </div>
                                    )}
                                </div>

                                <div className="card-header--item">
                                    {canAddAlerts && (
                                        <Button
                                            onClick={() =>
                                                setIsAddAlertModalOpen(true)
                                            }
                                        >
                                            <span className="ico">
                                                <PlusIcon />
                                            </span>
                                            {t('add_alert')}
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <div className="card-body overflow-hidden">
                                <div className="table-block h-full">
                                    <div className="table-header tabs-nav">
                                        {tabs.map((tab) => (
                                            <button
                                                key={tab.id}
                                                className={clsx(
                                                    'tab-link-2 btn btn--md',
                                                    {
                                                        'active-tab':
                                                            activeTab ===
                                                            tab.id,
                                                    },
                                                )}
                                                onClick={() =>
                                                    setActiveTab(tab.id)
                                                }
                                            >
                                                <div className="tab-link-2--content text--lg">
                                                    <span>{tab.label}</span>
                                                    <span className="count-pill">
                                                        {tab.id ===
                                                        'zip_codes_alerts'
                                                            ? zipCodes.length
                                                            : userData?.data
                                                                  ?.offenders
                                                                  ?.length}
                                                    </span>
                                                </div>
                                            </button>
                                        ))}
                                    </div>
                                    <div className="table-body overflow-hidden h-full">
                                        {activeTab === 'name_alerts' && (
                                            <div className="tab h-full active-tab">
                                                <NameAlertsTable
                                                    alerts={nameAlerts}
                                                    onDelete={() => {}}
                                                />
                                            </div>
                                        )}
                                        {activeTab === 'zip_codes_alerts' && (
                                            <div className="tab h-full active-tab">
                                                <ZipCodeAlertsTable
                                                    alerts={zipCodes}
                                                    onDelete={() => {}}
                                                    onEdit={(alert) =>
                                                        editAlertZip(alert)
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {userData?.data?.offenders.length && (
                                <div className="card-footer">
                                    <Pagination
                                        pageCount={
                                            activeTab === 'name_alerts'
                                                ? Math.ceil(
                                                      userData?.data?.offenders
                                                          ?.length / 10,
                                                  ) || 1
                                                : 1
                                        }
                                        currentPage={
                                            activeTab === 'name_alerts'
                                                ? currentPage
                                                : 1
                                        }
                                        onPageChange={(page) => {
                                            setCurrentPage(page)
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <AddAlertModal
                onSubmit={() => {}}
                isOpen={isAddAlertModalOpen}
                activeAlert={activeAlert}
                onClose={() => {
                    setActiveAlert(null)
                    setIsAddAlertModalOpen(false)
                }}
            />
        </main>
    )
}

export default AlertsPage
