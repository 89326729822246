import { useState } from 'react'

import { FAMILY_STATUS } from '../../constants/statuses'
import { useGetFamilyQuery, useGetSaveZonesQuery } from '../../services/family'
import type { IFamilyMember } from '../../types'

import FamilyOffenders from './components/FamilyOffenders'
import FamilySidebar from './components/FamilySidebar'

const FamilyMembersPage = () => {
    const [selectedUser, setSelectedUser] = useState<IFamilyMember | null>(null)
    const { data: zonesData, isLoading: zonesIsLoading } =
        useGetSaveZonesQuery('')
    const { data: familyData, isLoading: familyIsLoading } = useGetFamilyQuery()
    const familyMembers = familyData?.data || []
    const saveZonesData = zonesData?.data || []
    if (familyIsLoading || zonesIsLoading) return null

    return (
        <>
            <main className="content">
                <div className="container h-full">
                    <div className="catalog-page section-fullscreen headers-similars">
                        <FamilySidebar
                            saveZones={saveZonesData}
                            users={familyMembers}
                            selectedUser={selectedUser}
                            setSelectedUser={setSelectedUser}
                        />
                        <FamilyOffenders
                            saveZones={saveZonesData}
                            users={familyMembers.filter(
                                (user) => user.status !== FAMILY_STATUS.Pending,
                            )}
                            selectedUser={selectedUser}
                            setSelectedUser={setSelectedUser}
                        />
                    </div>
                </div>
            </main>
        </>
    )
}

export default FamilyMembersPage
