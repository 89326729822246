import { baseApi } from './base'
import type {
    INotification,
    IResponseType,
    IResponseWithoutData,
} from './types'

const notBase = 'api/notifications'

export const notificationsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllNotifications: builder.query<
            IResponseType<INotification[]>,
            string | void
        >({
            query: (qwery = '') => `${notBase}?${qwery}`,
            providesTags: ['notifications'],
        }),
        readAllNot: builder.query<IResponseWithoutData, void>({
            query: () => `${notBase}/read-all`,
        }),
        countNot: builder.query<IResponseType<{ count: number }>, void>({
            query: () => `/api/notifications/count`,
        }),
        addUpdateZipAlert: builder.mutation<any, any>({
            query: (body) => ({
                url: `/api/users/zipcodealert`,
                method: 'POST',
                body,
            }),
        }),
        deleteZipAlert: builder.mutation<
            IResponseType<{ offenders: string[] }>,
            { zipcodes: string[] }
        >({
            query: (body) => ({
                url: `/api/users/zipcodealert`,
                method: 'DELETE',
                body,
            }),
        }),
        addUser: builder.mutation<any, any>({
            query: (body) => ({
                url: `api/users/offender`,
                method: 'POST',
                body,
            }),
        }),
        deleteUser: builder.mutation<any, any>({
            query: (body) => ({
                url: `api/users/offender`,
                method: 'DELETE',
                body,
            }),
        }),
    }),
    overrideExisting: false,
})

export const {
    useLazyCountNotQuery,
    useLazyReadAllNotQuery,
    useDeleteUserMutation,
    useAddUserMutation,
    useDeleteZipAlertMutation,
    useAddUpdateZipAlertMutation,
    useLazyGetAllNotificationsQuery,
    useGetAllNotificationsQuery,
} = notificationsApi
