import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import type { Dispatch, MouseEventHandler, SetStateAction } from 'react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useOutletContext } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { InferType } from 'yup'
import { object, string } from 'yup'

import type { StepsProps } from '..'
import Button from '../../../compoments/Button'
import Checkbox, { CheckboxSizes } from '../../../compoments/Checkbox'
import FormHeader from '../../../compoments/FormHeader'
import Input from '../../../compoments/Input'
import PasswordInput from '../../../compoments/PasswordInput'
import type { FormContextValue } from '../../../layouts/FormLayout'
import {
    useRequestRecoveryMutation,
    useTestRecoveryCodeMutation,
} from '../../../services/auth'

const stepTexts = {
    1: {
        heading: 'Reset password',
        description: 'No worries, we’ll send you instructions.',
    },
    2: {
        heading: 'Enter your code',
        description: (
            <span>
                We sent a code to <br />{' '}
                <a
                    className="color-secondary fw-600 lh-26"
                    href="mailto:jackson.graham@example.com"
                >
                    jackson.graham@example.com
                </a>
            </span>
        ),
    },
    3: {
        heading: 'Set new password',
        description: 'Must be at least 8 characters',
    },
}

const schema = object({
    email: string().email('Invalid email format').required('Email is required'),
})
type FormType = InferType<typeof schema>
interface Step3Props extends StepsProps {
    email: string
    setResetStep: Dispatch<SetStateAction<number>>
    setResetCode: Dispatch<SetStateAction<number | null>>
}

const Step3: React.FC<Step3Props> = ({
    setStep,
    email,
    setResetStep,
    setResetCode,
}) => {
    // const { formHeight, setFormHeight } = useOutletContext<FormContextValue>()
    // useEffect(() => {
    //   setFormHeight('min-h-500')
    // })
    const inputRef = useCallback((code: HTMLDivElement | null) => {
        if (code !== null) {
            const input_childs = code.querySelectorAll<HTMLInputElement>(
                "input[type='number']",
            )

            if (input_childs.length > 0) {
                const first_input = input_childs[0]
                const form_closest = first_input.closest('form')
                // console.log(111)
                const get_notfocus_inputs = function () {
                    const not_focus_inputs_all =
                        code.querySelectorAll<HTMLInputElement>(
                            'input:not([autofocus])',
                        )
                    for (let i = 0; i < not_focus_inputs_all.length; i++) {
                        not_focus_inputs_all[i].disabled = true
                    }
                }
                if (first_input.getAttribute('autofocus') !== null) {
                    get_notfocus_inputs()
                } else {
                    first_input.setAttribute('autofocus', 'true')
                    get_notfocus_inputs()
                }

                for (let i = 0; i < input_childs.length; i++) {
                    const inp = input_childs[i]
                    inp.addEventListener('input', function (ev) {
                        const target = ev?.target as HTMLInputElement
                        if (parseInt(target?.value, 10) >= 0) {
                            target.classList.add('active-value')
                        } else {
                            target.classList.remove('active-value')
                        }
                        console.log(222)
                        const val_inp = target.value
                        if (val_inp !== '') {
                            inp.disabled = true
                            const next_inp = input_childs[i + 1]
                            if (input_childs[i + 1]) {
                                next_inp.disabled = false
                                next_inp.focus()
                            } else {
                                // submit form (optional)
                                if (form_closest) {
                                    const submit_form =
                                        form_closest.querySelector<HTMLButtonElement>(
                                            "[type='submit']",
                                        )
                                    // submit_form?.click()
                                }
                            }
                        }

                        document.addEventListener('keydown', function (ev) {
                            ev.stopPropagation()
                            if (ev.keyCode === 69) {
                                inp.value = ''
                            }
                        })
                        document.addEventListener('keyup', function (ev) {
                            ev.stopPropagation()
                            if (ev.keyCode === 69) {
                                inp.value = ''
                            }
                        })
                    })
                }
            }
        }
    }, [])
    const [sendCode, sendCodeOptions] = useTestRecoveryCodeMutation()
    const [recovery, recoveryOptions] = useRequestRecoveryMutation()

    const [code, setCode] = useState<string[]>(Array(6).fill(''))
    console.log(code)
    const [currentInputIndex, setCurrentInputIndex] = useState<number>(0)
    const handleInputChange = (index: number, value: string) => {
        console.log('ON CHANge')
        const newCode = [...code]
        newCode[index] = value
        setCode(newCode)
        console.log(index, value)
        if (value.length === 1 && index < 5 - 1) {
            // Переключаем фокус на следующий инпут
            inputsRef.current[index + 1].focus()
        }
        if (value.length === 1) {
            setCurrentInputIndex(Math.min(index + 1, 6 - 1))
        } else if (value === '') {
            setCurrentInputIndex(Math.max(index - 1, 0))
            console.log('hello')
            inputsRef.current[index - 1].focus()
        }

        if (newCode.every((digit) => digit.length === 1)) {
            console.log(newCode.join(''))
        }
    }
    const handleChange = (value: string, index: number) => {
        const newValue = value.slice(0, 1)
        const newCode = [...code]
        newCode[index] = newValue

        setCode(newCode)
        console.log(inputsRef.current[index + 1])
        // Move focus to the next input
        if (value && index < 6 - 1) {
            inputsRef.current[index + 1]?.focus()
        }

        // Check if the code is complete
        if (newCode.every((num) => num !== '')) {
            console.log(newCode.join(''))
        }
    }
    const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
        if (e.key === 'Backspace' && !code[index] && index > 0) {
            inputsRef.current[index - 1]?.focus()
        }
        console.log(e.key)
    }
    const inputsRef = useRef<Array<HTMLInputElement | null>>([])
    const [errors, setErrors] = useState<boolean[]>(Array(6).fill(false))
    const handleSubmit: MouseEventHandler<HTMLButtonElement> = async (e) => {
        e.preventDefault()
        setErrors(Array(6).fill(false))
        let hasErrors = false
        setErrors(
            code.map((value) => {
                if (!value) hasErrors = true
                return !value
            }),
        )
        if (hasErrors) return
        try {
            const resp = await sendCode({
                email,
                code: Number(code.join('')),
            }).unwrap()
            console.log('fulfilled', resp)
            if (!resp.success) {
                toast.error(resp.message)
                return
            }
            setResetStep(3)
            setResetCode(Number(code.join('')))
        } catch (error) {
            console.error('rejected', error)
        }
    }
    const [resendText, setResendText] = useState('Resend')
    const handleResend: MouseEventHandler<HTMLButtonElement> = async (e) => {
        try {
            const resp = await recovery(email).unwrap()
            console.log('fulfilled', resp)
            if (!resp.success) {
                toast.error(resp.message)
                return
            }
            setResendText('Code sent!')
            setTimeout(() => {
                setResendText('Resend')
            }, 2000)
            // if (resp.success && resp.data) {
            //   window.localStorage.setItem('authToken', resp.data.token)
            //   toast.success('Login successfully!')
            //   navigate('/')
            // } else {
            //   toast.error(resp?.message)
            // }
        } catch (error) {
            console.error('rejected', error)
        }
    }
    const handlePaste = (e: React.ClipboardEvent) => {
        const pastedData = e.clipboardData.getData('text').trim()
        console.log(pastedData)
        if (/^\d{6}$/.test(pastedData)) {
            const newCode = pastedData.split('')
            setCode(newCode)

            newCode.forEach((value, index) => {
                inputsRef.current[index]!.value = value
            })

            // После вставки, переводим фокус на последний инпут
            inputsRef.current[5]?.focus()
        }
    }
    return (
        <>
            <FormHeader
                hasArrow
                handleBack={() => setStep(1)}
                heading="Enter your code"
                description={
                    <span>
                        We sent a code to <br />{' '}
                        <a
                            className="color-secondary fw-600 lh-26"
                            href={`mailto:${email}`}
                        >
                            {email}
                        </a>
                    </span>
                }
            />
            <div className="form-body">
                <div className="row">
                    <div className="code w-full mx-auto" id="code-input">
                        {code.map((digit, index) => (
                            <div className="code-col">
                                <label className="code-item">
                                    <input
                                        type="number"
                                        inputMode="numeric"
                                        maxLength={1}
                                        value={digit}
                                        onChange={(e) =>
                                            handleChange(e.target.value, index)
                                        }
                                        onKeyDown={(e) =>
                                            handleKeyDown(e, index)
                                        }
                                        // autoFocus={index === currentInputIndex}
                                        ref={(el) =>
                                            (inputsRef.current[index] = el)
                                        }
                                        className={clsx(
                                            digit && 'active-value',
                                        )}
                                        onPaste={handlePaste}
                                    />

                                    <div
                                        className="code-block"
                                        style={{
                                            borderColor: errors[index]
                                                ? 'var(--danger)'
                                                : '#E2E2E2',
                                        }}
                                    ></div>
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="form-footer">
                <div className="row">
                    <div className="col-12">
                        <Button
                            type="submit"
                            onClick={handleSubmit}
                            className="mb-28"
                            isLoading={sendCodeOptions.isLoading}
                        >
                            Submit
                        </Button>
                        <div className="flex items-center justify-center gap-10">
                            <span className="text--md">
                                Don’t receive the email?
                            </span>
                            <button
                                disabled={
                                    recoveryOptions.isLoading ||
                                    resendText === 'Code sent!'
                                }
                                className="color-secondary text--md fw-600"
                                onClick={handleResend}
                            >
                                {recoveryOptions.isLoading
                                    ? 'Sending...'
                                    : resendText}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Step3
