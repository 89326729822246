import { yupResolver } from '@hookform/resolvers/yup'
import { getCountries, getPhoneCode } from 'libphonenumber-js'
import type { FC } from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import type { StepsProps } from '..'
import Button from '../../../compoments/Button'
import FormHeader from '../../../compoments/FormHeader'
import Input from '../../../compoments/Input'
import SelectBox from '../../../compoments/SelectBox'
import { registerSchema } from '../../../shemas/validationSchema'

interface IData {
    fullName: string
    email: string
    phoneCountry: string
    phone: string
}

const Step1: FC<StepsProps> = ({
    setStep,
    setActiveFormData,
    activeFormData,
}) => {
    console.log('1')

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({ resolver: yupResolver(registerSchema) })

    const onSubmit = (data: IData) => {
        console.log(data)
        setActiveFormData({ ...data })
        setStep(2)
    }

    useEffect(() => {
        setValue('email', activeFormData?.email || '')
        setValue('fullName', activeFormData?.fullName || '')
        setValue('phoneCountry', activeFormData?.phoneCountry || '')
        setValue('phone', activeFormData?.phone || '')
    }, [])
    const countries = getCountries()

    return (
        <>
            <FormHeader
                hasLogo
                heading="Create an account"
                description="Fill in form to create your account."
            />
            <div className="form-body">
                <div className="row">
                    <div className="col-md-12 col-12 mb-22">
                        <Input
                            label="Full name"
                            {...register('fullName')}
                            error={errors?.fullName?.message}
                        />
                    </div>
                    <div className="col-md-12 col-12 mb-22">
                        <Input
                            label="Email"
                            {...register('email')}
                            error={errors?.email?.message}
                        />
                    </div>
                    <div className="col-md-12 col-12 mb-28">
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group select--outline input--lg">
                                    <label className="form-label">
                                        <div className="label--text">
                                            <span>Country</span>
                                        </div>
                                    </label>
                                    <SelectBox
                                        wrapperClassName={`select--outline input--sm standard-height ${errors.phoneCountry?.message ? 'select-error' : ''}`}
                                        options={countries.map((item) => ({
                                            label: `${item} +${getPhoneCode(item)}`,
                                            value: item,
                                        }))}
                                        selectValue={getValues('phoneCountry')}
                                        onChange={(option) =>
                                            setValue(
                                                'phoneCountry',
                                                option?.value as string,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-8">
                                <Input
                                    label="Phone number"
                                    {...register('phone')}
                                    error={errors?.phone?.message}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="form-footer">
                <div className="row">
                    <div className="col-12 mb-22">
                        <Button type="button" onClick={handleSubmit(onSubmit)}>
                            Continue
                        </Button>
                    </div>
                    <div className="col-12">
                        <div className="flex items-center justify-center gap-10">
                            <span className="text--md">
                                Already have an account?
                            </span>
                            <Link
                                to="/login-email"
                                className="color-secondary text--md fw-600"
                            >
                                Log In
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Step1
