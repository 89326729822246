import { useEffect } from 'react'

import { useGetMeQuery } from './services/auth'
import { useLazyGetAllNotificationsQuery } from './services/notifications'
import { PremiumStatus } from './types'
import { Navigate } from 'react-router-dom'

export const ServiceComponent = () => {
    const { data, isLoading } = useGetMeQuery()
    const [triggerNotifications] = useLazyGetAllNotificationsQuery()
   
    useEffect(() => {
        if (data?.data?.id) {
            triggerNotifications()
        }
    }, [data])
    return <></>
}
