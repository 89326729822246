import React from 'react'
import { Outlet } from 'react-router-dom'

const FormLayout: React.FC = () => {
    return (
        <div className="wrapper">
            <main className="content flex justify-center login-bg">
                <section className="section-center">
                    <div className="container">
                        <div className="form form-login m-auto min-h-591">
                            <Outlet />
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default FormLayout
