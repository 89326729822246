import clsx from 'clsx'
import type { ButtonHTMLAttributes } from 'react'
import React, { useState } from 'react'

import Spinner from './Spinner'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    isLoading?: boolean
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    children: React.ReactNode
}

const Button: React.FC<ButtonProps> = ({ children, isLoading, ...rest }) => {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (rest.onClick) {
            rest.onClick(event)
        }
    }

    return (
        <button
            {...rest}
            disabled={isLoading}
            onClick={handleClick}
            className={clsx(
                'btn btn--primary btn--lg radius-full w-full',
                rest.className,
            )}
        >
            {isLoading && (
                <div className="btn-loader">
                    <Spinner />
                </div>
            )}
            {children}
            {/* {isLoading ? <Spinner /> : children} */}
        </button>
    )
}

export default Button
